import axios from "axios"
import { chestsAddress } from "config"

export const getUserChestsOpened = async (address: string) => {
    try {
        const { data } = await axios.get(
            `https://mvx-api.estar.games/accounts/${address}/transactions?size=10000&sender=${address}&receiver=${chestsAddress}&status=success&function=openLootbox`
            )
        return data.length;
    } catch (error) {
        return 0;
    }
} 