import { Nav, NavItem } from 'react-bootstrap';

import { useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { observer } from 'mobx-react-lite';
import account from 'store/AccountStore';
import { Beer, Bread, Iron, Shield, Stone, Wood } from 'assets/icons';
import { useEffect } from 'react';

export const ResourcesMenu = observer(() => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = useGetIsLoggedIn();
  const resourcesList = [
    {
      name: 'Food',
      icon: <Bread width={16} fill='black' />,
      balance: account.foodBalance
    },
    {
      name: 'Wood',
      icon: <Wood width={16} fill='black' />,
      balance: account.woodBalance
    },
    {
      name: 'Beer',
      icon: <Beer width={16} fill='black' />,
      balance: account.beerBalance
    },
    {
      name: 'Iron',
      icon: <Iron width={20} fill='black' />,
      balance: account.ironBalance
    },
    {
      name: 'Stone',
      icon: <Stone width={18} fill='black' />,
      balance: account.stoneBalance
    },
    {
      name: 'WarGear',
      icon: <Shield width={14} fill='black' />,
      balance: account.wargearBalance
    }
  ];

  useEffect(() => {
    account.loadFoodBalance(address)
    account.loadWoodBalance(address)
    account.loadBeerBalance(address)
    account.loadIronBalance(address)
    account.loadStoneBalance(address)
    account.loadWargearBalance(address)
  }, [address])

  if (!isLoggedIn) return null;
  return (
    <Nav className='d-none mx-auto d-sm-flex mx-sm-0 justify-content-center align-items-center mt-3 resources-menu text-center'>
      {resourcesList.map((resource, index) => (
        <ResourceItem key={resource.name + index} resource={resource} />
      ))}
    </Nav>
  );
});

export const ResourceItem = observer(({
  resource
}: {
  resource: { name: string; icon: any, balance: number };
}) => {
  return (
    <NavItem key={resource.name} className={'mb-3 mb-sm-0 mr-0 mr-sm-4'}>
      <div>
        {resource.icon}
        <span className='ml-3'>{resource.name}</span>
      </div>
      <div className='d-flex justify-content-center'>
        {resource.balance}
        <span className='ml-3'>+0</span>
      </div>
    </NavItem>
  );
});
