import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Row, Table } from 'react-bootstrap'
import { Boss, PlayerAttack } from 'utils/types'

interface Props {
    attacks: PlayerAttack[]
}

const BossAttackLog: FC<Props> = observer(({ attacks }) => {
    return (
        <>
            <Row className='mt-3'>
                <Col xs={12}>
                    <h1 className='text-center'>Raid Stats</h1>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    <Table className='mission-log' responsive>
                        <thead>
                            <tr>
                                <th>Position</th>
                                <th>Address</th>
                                <th>Damage</th>
                                <th>Reward</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attacks.map((attack, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{attack.address}</td>
                                    <td>{(Number(attack.attack) / 1000).toLocaleString()}</td>
                                    <td>{attack.reward.toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
})

export default BossAttackLog
