import React from 'react'
import { Button } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { routeNames } from 'routes'

const BazaarSelector = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation();
  return (
    <div className='d-flex' style={{
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5
    }}>
        <Button className={`custom-btn ${routeNames.bazaar === pathname && 'btn-disabled'}`} onClick={() => navigate(routeNames.bazaar)}>
            Shop
        </Button>
        <Button className={`custom-btn ${routeNames.craft === pathname && 'btn-disabled'}`} onClick={() => navigate(routeNames.craft)}>
            Craft
        </Button>
        <Button className={`custom-btn ${routeNames.auction === pathname && 'btn-disabled'}`} onClick={() => navigate(routeNames.auction)}>
            Auction
        </Button>
    </div>
  )
}

export default BazaarSelector