import { Address } from "@multiversx/sdk-core/out";
import { getChainID, refreshAccount } from "@multiversx/sdk-dapp/utils"
import { raidAddress } from "config";
import { raidAbi } from "utils/abi/raid";
import { getSmartContract } from "utils/functions/getSmartContract";
import { sendTransactions } from "@multiversx/sdk-dapp/services";

export const sendDistributeTransaction = async (address: string) => {
    const contract = getSmartContract(raidAbi, new Address(raidAddress))
    const transaction = contract.methods.distributeReward()
    .withSender(new Address(address))
    .withChainID(getChainID())
    .withGasLimit(30_000_000)
    .buildTransaction()
    refreshAccount();
    sendTransactions({
        transactions: transaction
    });
}
