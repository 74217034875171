import React, { FC, useState } from 'react'
import { Boss } from 'utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { Sword } from 'assets/icons';
import BigNumber from 'bignumber.js';
import CustomModal from 'components/CustomModal';
import { DisplaySftsForRaid } from 'components/DisplaySftsForRaid';
import { observer } from 'mobx-react-lite';
import account from 'store/AccountStore';
import { raidCharacters } from 'config';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { sendDistributeTransaction } from '../hooks/sendDistributeTransaction';
import { sendFightTransaction } from '../hooks/sendFightTransaction';

interface Props {
  boss?: Boss
}

const BossCard: FC<Props> = observer(({ boss }) => {
  const { address } = useGetAccountInfo();
  const [show, setShow] = useState(false);
  const { sfts } = account;

  const handleCloseModal = () => setShow(false);

  const distributeReward = () => {
    if(!boss) return
    if(boss.hp > 0) return
    sendDistributeTransaction(address);
  }

  if(!boss) return (
    <div className="mission-card" style={{ position: 'relative' }}>
      <div className="mission-card-item-id">
          <h3>Grrr...</h3>
      </div>
      <img
          src={'https://cdn.midjourney.com/f98dd3fc-48e7-4d2a-ba97-187dc926e07e/0_3.webp'}
          alt="raid"
          width={'100%'}
          className='border-radius-20'
      />
    </div>
  );
  return (
    <>
    <div className="mission-card" style={{ position: 'relative' }}>
        <div className="mission-card-item-id">
            <h3>{boss.name}</h3>
        </div>
        <img
            src={boss.image}
            alt="raid"
            width={'100%'}
            className='border-radius-20'
        />
        <div style={{
          position: 'absolute',
          bottom: '1%',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          {
            boss.hp > 0 ? <button
              className='mission-card-item-content-btn mb-2'
              onClick={() => setShow(true)}
            >
              Fight
            </button>:
            <button
              className='mission-card-item-content-btn mb-2'
              onClick={distributeReward}
            >
              Distribute
            </button>
          }
          {/* Stats */}
          <div style={{
            display: 'flex',
            gap: '15px'
          }}>
            <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '17px',
              }}>{(boss.hp / 1000).toLocaleString()}</span>
              <FontAwesomeIcon icon={faHeart} color='white' fontSize={20} />
            </div>
            <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '17px',
              }}>{(boss.attack / 1000).toLocaleString()}</span>
              <Sword width={22} height={22} fill='white' />
            </div>
            <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '17px',
              }}>{
                new BigNumber(boss?.prize_pool.amount || 0).div(Math.pow(10, 18)).toNumber().toLocaleString()
              }</span>
              <img src={`https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/${boss?.prize_pool.identifier}/logo.svg`} width={22} height={22} alt='token' />
            </div>
          </div>
        </div>
    </div>
    <CustomModal show={show} onHide={handleCloseModal} title='Select Characters'>
      <DisplaySftsForRaid sfts={sfts.filter((sft) => raidCharacters.includes(sft.nonce))} onCloseModal={handleCloseModal} func={sendFightTransaction} />
    </CustomModal>
    </>
  )
})

export default BossCard