import axios from "axios";
import { COLLECTION_IDENTIFIER, MICROSERVICE_URL } from "config";
import { Fighter } from "utils/types";

export const getFighters = async (): Promise<Fighter[]> => {
    try {
        const { data } = await axios.get(MICROSERVICE_URL + '/raid/collections/' + COLLECTION_IDENTIFIER)
        return data
    } catch (error) {
        console.log(error)
        return [];
    }
}