import { PageLayout } from '../../components/PageLayout';
import { Col, Row } from 'react-bootstrap';
import CastleMap from "./components/CastleMap"
import { observer } from 'mobx-react-lite';

export const MapPage = observer(() => {
  return (
    <PageLayout>
      <Row className='mb-3'>
        <Col>
          <h1 className='text-center'>Castle Map</h1>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col xs={12}>
          <CastleMap />
        </Col>
      </Row>
    </PageLayout>
  );
});