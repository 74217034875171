import { makeAutoObservable, runInAction } from 'mobx';
import AccountService from './services/AccountService';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import { ActiveMission, NftTypeWithQuantity, Reward, SFTStaked, UserMission } from '../utils/types';

class AccountStore {
  loading: boolean = false;
  sfts: NftTypeWithQuantity[] = [];
  sftsStaked: SFTStaked[] = [];
  rewards: Reward[] = [];
  estarBalance: number = 0;
  eccuBalance: number = 0;
  foodBalance: number = 0;
  woodBalance: number = 0;
  beerBalance: number = 0;
  ironBalance: number = 0;
  stoneBalance: number = 0;
  wargearBalance: number = 0;

  // mission
  activeMissions: ActiveMission[] = [];
  missionsHistory: any[] = [];

  constructor(public readonly accountService: AccountService) {
    makeAutoObservable(this);
    this.accountService = accountService;
  }

  async loadEstarBalance(address: string): Promise<void> {
    const estar: number = await this.accountService.getEstarBalance(address);

    if (estar === 0) return;
    runInAction(() => {
      this.estarBalance = estar;
    });
  }

  async loadFoodBalance(address: string): Promise<void> {
    const food: number = await this.accountService.getFoodBalance(address);

    if (food === 0) return;
    runInAction(() => {
      this.foodBalance = food;
    });
  }

  async loadWoodBalance(address: string): Promise<void> {
    const wood: number = await this.accountService.getWoodBalance(address);

    if (wood === 0) return;
    runInAction(() => {
      this.woodBalance = wood;
    });
  }

  async loadBeerBalance(address: string): Promise<void> {
    const beer: number = await this.accountService.getBeerBalance(address);

    if (beer === 0) return;
    runInAction(() => {
      this.beerBalance = beer;
    });
  }

  async loadIronBalance(address: string): Promise<void> {
    const iron: number = await this.accountService.getIronBalance(address);

    if (iron === 0) return;
    runInAction(() => {
      this.ironBalance = iron;
    });
  }

  async loadStoneBalance(address: string): Promise<void> {
    const stone: number = await this.accountService.getStoneBalance(address);

    if (stone === 0) return;
    runInAction(() => {
      this.stoneBalance = stone;
    });
  }

  async loadWargearBalance(address: string): Promise<void> {
    const wargear: number = await this.accountService.getWargearBalance(
      address
    );

    if (wargear === 0) return;
    runInAction(() => {
      this.wargearBalance = wargear;
    });
  }

  async loadRewards(address: string): Promise<void> {
    const rewards = await this.accountService.fetchRewards(address);
    if (rewards.length > 0) {
      runInAction(() => {
        this.rewards = rewards;
      });
    }
  }

  async loadEstar(address: string): Promise<void> {
    this.loading = true;
    const estar: number = await this.accountService.getEstarBalance(address);
    if (estar > 0) {
      runInAction(() => {
        this.estarBalance = estar;
      });
    }
    this.loading = false;
  }

  async loadEccu(address: string): Promise<void> {
    this.loading = true;
    const eccu: number = await this.accountService.getEccuBalance(address);

    if (eccu > 0) {
      runInAction(() => {
        this.eccuBalance = eccu;
      });
    }

    this.loading = false;
  }

  async loadSFTsOnChain(address: string): Promise<void> {
    this.loading = true;
    const sftsFound: NftTypeWithQuantity[] = await this.accountService.fetchSfts(address);
    console.log(sftsFound);
    if (sftsFound.length > 0) {
      runInAction(() => {
        this.sfts = sftsFound.map((sft) => ({
          ...sft,
          quantity: 1,
        }));
      });
    }

    this.loading = false;
  }

  async loadSFTsStaked(address: string): Promise<void> {
    this.loading = true;
    const sftsStaked = await this.accountService.fetchSftsStaked(address);

    if (sftsStaked.length > 0) {
      runInAction(() => {
        this.sftsStaked = sftsStaked;
      });
    }

    this.loading = false;
  }

  increaseSftQuantity(sft: NftTypeWithQuantity) {
    const index = this.sfts.findIndex((sftOnChain) => sftOnChain.identifier === sft.identifier);
    if(index > -1) {
      const sftFound = this.sfts[index];
      if(sftFound.quantity === Number(sftFound.balance)) return;
      sftFound.quantity += 1;
      this.sfts[index] = sftFound;
    }
  }

  setMaxSftQuantity(sft: NftTypeWithQuantity) {
    const index = this.sfts.findIndex((sftOnChain) => sftOnChain.identifier === sft.identifier);
    if(index > -1) {
      const sftFound = this.sfts[index];
      sftFound.quantity = Number(sft.balance);
      this.sfts[index] = sftFound;
    }
  }

  setMinSftQuantity(sft: NftTypeWithQuantity) {
    const index = this.sfts.findIndex((sftOnChain) => sftOnChain.identifier === sft.identifier);
    if(index > -1) {
      const sftFound = this.sfts[index];
      sftFound.quantity = 1;
      this.sfts[index] = sftFound;
    }
  }

  decreaseSftQuantity(sft: NftTypeWithQuantity) {
    const index = this.sfts.findIndex((sftOnChain) => sftOnChain.identifier === sft.identifier);
    if(index > -1) {
      const sftFound = this.sfts[index];
      if(sftFound.quantity <= 1) return;
      sftFound.quantity -= 1;
      this.sfts[index] = sftFound;
    }
  }

  async loadActiveMission(address: string): Promise<void> {
    this.loading = true;
    const missions = await this.accountService.fetchActiveMission(address);

    runInAction(() => {
      this.activeMissions = missions;
    });

    this.loading = false;
  }

  async loadMissionsHistory(address: string): Promise<void> {
    this.loading = true;
    const missions = await this.accountService.fetchMissionsHistory(address);

    if (missions.length > 0) {
      runInAction(() => {
        this.missionsHistory = missions;
      });
    }

    this.loading = false;
  }

  getKeys(): any {
    return this.sfts.filter((sft) => sft.nonce === 1);
  }

  reset(): void {
    this.sfts = [];
    this.sftsStaked = [];
  }
}

const account = new AccountStore(new AccountService());

export default account;
