import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CustomModal from 'components/CustomModal';
import CastleMapModalContent from './CastleMapModalContent';
import { sftStore } from '../store/SftStore';
import { routeNames } from 'routes';
import { useNavigate } from 'react-router-dom';

const CastleMap: FC = observer(() => {
  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState<string>('');
  const navigate = useNavigate();

const handleClick = (target: string) => {
    if(!target) return;
    setShow(true);
    setTarget(target);
};

const handleClose = () => {
    setShow(false)
    setTarget('')
    sftStore.resetSft()
}

return (
    <>
        <CustomModal show={show} onHide={handleClose} title={target} capitalize>
            <CastleMapModalContent target={target} />
        </CustomModal>
        <div style={{ overflow: 'auto', width: '100%', position: 'relative' }}>
            <div>
                <img
                    src={require('../../../assets/img/map.jpeg')}
                    useMap="#image-map"
                    width={'970px'}
                    height={'100%'}
                    style={{ display: 'block', margin: '0 auto' }}
                />
                <map name="image-map">
                    <area
                        onClick={() => handleClick('ironmine')}
                        style={{ cursor: 'pointer' }}
                        alt="Iron Mine"
                        title="Iron Mine"
                        coords="211,405,178,381,137,412,142,438,161,460,188,470,212,455,219,417"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('lumberyard')}
                        style={{ cursor: 'pointer' }}
                        alt="LumberYard"
                        title="LumberYard"
                        coords="418,715,321,701,277,663,238,658,157,662,114,678,48,723,37,730,422,730"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('lumberyard')}
                        style={{ cursor: 'pointer' }}
                        alt="LumberYard"
                        title="LumberYard"
                        coords="3,660,161,531,4,396"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('house')}
                        style={{ cursor: 'pointer' }}
                        alt="House"
                        title="House"
                        coords="337,523,206,630,439,660,548,623,542,611"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('tower')}
                        style={{ cursor: 'pointer' }}
                        alt="tower"
                        title="Tower"
                        coords="343,458,321,486,331,518,368,521,591,606,731,587,773,541,815,500,815,387,788,309,701,
                                227,636,184,563,145,531,116,500,132,503,181,542,187,552,164,639,209,702,259,749,307,
                                792,388,799,432,792,478,771,507,742,531,699,537,687,558,676,568,598,577,491,544,382,497"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('farm')}
                        style={{ cursor: 'pointer' }}
                        alt="Farm"
                        title="Farm"
                        coords="116,281,131,221,66,195,49,250,56,295"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('farm')}
                        style={{ cursor: 'pointer' }}
                        alt="Farm"
                        title="Farm"
                        coords="126,303,63,326,118,408,162,363"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('blacksmith')}
                        style={{ cursor: 'pointer' }}
                        alt="Blacksmith"
                        title="Blacksmith"
                        coords="338,301,286,371,319,394,352,363,372,321"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('forge')}
                        style={{ cursor: 'pointer' }}
                        alt="Forge"
                        title="Forge"
                        coords="323,292,276,273,244,306,244,327,264,352,291,338"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('brewery')}
                        style={{ cursor: 'pointer' }}
                        alt="Brewery"
                        title="Brewery"
                        coords="386,306,426,259,364,223,324,278"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('farm')}
                        style={{ cursor: 'pointer' }}
                        alt="Farm"
                        title="Farm"
                        coords="652,626,728,601,761,671,707,693,648,702"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('farm')}
                        style={{ cursor: 'pointer' }}
                        alt="Farm"
                        title="Farm"
                        coords="747,575,791,535,863,564,824,619,771,643"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('farm')}
                        style={{ cursor: 'pointer' }}
                        alt="Farm"
                        title="Farm"
                        coords="829,378,902,370,903,421,902,481,824,460"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('farm')}
                        style={{ cursor: 'pointer' }}
                        alt="Farm"
                        title="Farm"
                        coords="814,291,843,350,911,339,899,290,857,240"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('farm')}
                        style={{ cursor: 'pointer' }}
                        alt="Farm"
                        title="Farm"
                        coords="761,177,716,229,782,289,832,239,802,204"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('mill')}
                        style={{ cursor: 'pointer' }}
                        alt="Mill"
                        title="Mill"
                        coords="782,35,749,25,738,53,734,83,764,83,764,110,781,133,803,133,818,146,822,155,836,
                                170,861,162,881,142,836,110,826,115,822,81,794,64"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('quarry')}
                        style={{ cursor: 'pointer' }}
                        alt="Quarry"
                        title="Quarry"
                        coords="662,4,658,36,643,57,621,54,591,57,562,47,558,26,543,2"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('tavern')}
                        style={{ cursor: 'pointer' }}
                        alt="Tavern"
                        title="Tavern"
                        coords="579,252,562,227,537,234,526,262,531,292,548,301,567,281"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('bank')}
                        style={{ cursor: 'pointer' }}
                        alt="Bank"
                        title="Bank"
                        coords="726,329,688,315,656,326,656,351,672,356,702,360,728,349"
                        shape="poly" />
                    <area
                        onClick={() => handleClick('castle')}
                        style={{ cursor: 'pointer' }}
                        alt="Castle"
                        title="Castle"
                        coords="703,384,627,372,614,391,614,420,633,432,673,450,697,447,717,419"
                        shape="poly" />
                    <area
                        onClick={() => navigate(routeNames.missions)}
                        style={{ cursor: 'pointer' }}
                        alt="Missions"
                        title="Missions"
                        coords="616,484,597,454,564,457,557,479,574,495,574,506,593,511,611,510"
                        shape="poly"
                    />
                </map>
            </div>
            <img src={require('../../../assets/img/cloud.png')} alt="anim" width={150} style={{
                position: 'absolute',
                top: 30,
                left: 90,
            }} className='anim' />

            <img src={require('../../../assets/img/cloud.png')} alt="anim" width={120} style={{
                position: 'absolute',
                bottom: 25,
                left: '35%',
                transform: "translateX(-65%)"
            }}
            className='anim-bottom'
            />
        </div>
    </>
  );
});

export default CastleMap;
