import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PageLayout } from '../../components/PageLayout';
import { Button, Col, Row } from 'react-bootstrap';
import { Map, Tile, TileType } from 'utils/types';
import { useParams } from 'react-router-dom';
import { getIsland } from './hooks/getIsland';
import CustomModal from 'components/CustomModal';
import Canvas from './components/Canvas';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { deploy } from './hooks/deploy';
import { toast } from 'sonner';
import { deployOutpost } from './hooks/deployOutpost';

const tileSize = 10;

export const Island = observer(() => {
  const { id } = useParams();
  const { address } = useGetAccountInfo();
  const [map, setMap] = useState<Map>();
  const [userCastle, setUserCastle] = useState<Tile>();
  const [userOutposts, setUserOutposts] = useState<Tile[]>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showAvailable, setShowAvailable] = useState<boolean>(false);
  const [showCoord, setShowCoord] = useState<boolean>(false);
  const [scale, setScale] = useState(4);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [tile, setTile] = useState<Tile>();

  useEffect(() => {
    if(!id) return;
    setOffset({
      x: -1515,
      y: -1515
    })
    getIsland(id).then((data) => {
      if(!data) return
      updateMap(data)
    })
  }, [id])

  const centerCanvasOnCoordinates = (targetX: number, targetY: number) => {
    if(!canvasRef.current) return
    const newXOffset = Math.ceil(canvasRef.current.width / 2) - (targetX * tileSize * scale);
    const newYOffset = Math.ceil(canvasRef.current.height / 2) - (targetY * tileSize * scale);
    setOffset({ x: newXOffset, y: newYOffset });
  };

  const handleDeploy = (x: number, y: number) => {
    if(!id) return
    deploy(id, x, y, address).then((data: Map) => {
      updateMap(data)
      toast.success('Your castle has been successfully placed!')
    }).catch((e) => toast.error(e.response.data.message))
  }

  const handleDeployOutpost = (x: number, y: number) => {
    if(!id) return
    deployOutpost(id, x, y, address).then((data: Map) => {
      updateMap(data)
      toast.success('Your outpost has been successfully placed!')
    }).catch((e) => toast.error(e.response.data.message))
  }

  const updateMap = (newMap: Map) => {
    if(!newMap) return
      setMap(newMap)

      const castle = newMap.tiles.find((tile) => tile.owner === address && tile.type === TileType.CASTLE)
      if(castle) {
        setUserCastle(castle)
        setUserOutposts([castle])
      }

      const outposts = newMap.tiles.filter((tile) => tile.owner === address && tile.outpostLevel > 0);
      if(outposts.length > 0) setUserOutposts((prev) => [...prev, ...outposts])

      const foundTile = newMap.tiles.find((item) => item.x === tile?.x && item.y === tile?.y)
      if(foundTile) setTile(foundTile)
  }

  const isTileVisible = () => userOutposts.some(outpostTile => {
    if(!tile) return
    const distanceFromOutpost = Math.abs(tile.x - outpostTile.x) + Math.abs(tile.y - outpostTile.y);
    return distanceFromOutpost <= 5;
  });

  return (
    <PageLayout>
      <div style={{
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none'
      }}>
        <Row className='mb-3'>
          <Col>
            <h1 className='text-center'>Island</h1>
          </Col>
        </Row>
        {/* <ComingSoon /> */}
        <Row className='mb-3'>
          <Col xs={12}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <div className='mb-2 d-flex' style={{ gap: 5 }}>
                { !userCastle && <Button className='custom-btn' onClick={() => setShowAvailable(!showAvailable)}>Show Available</Button> }
                <Button className='custom-btn' onClick={() => setShowCoord(!showCoord)}>Show Coord</Button>
                <Button className='custom-btn' onClick={() => centerCanvasOnCoordinates(25, 25)}>Center N-W</Button>
                <Button className='custom-btn' onClick={() => centerCanvasOnCoordinates(75, 25)}>Center N-E</Button>
                <Button className='custom-btn' onClick={() => centerCanvasOnCoordinates(25, 75)}>Center S-W</Button>
                <Button className='custom-btn' onClick={() => centerCanvasOnCoordinates(75, 75)}>Center S-E</Button>
              </div>
              <Canvas
                canvasRef={canvasRef}
                map={map}
                userOutposts={userOutposts}
                offset={offset}
                scale={scale}
                showAvailable={showAvailable}
                showCoord={showCoord}
                setOffset={setOffset}
                setScale={setScale}
                setTile={setTile}
              />
            </div>
          </Col>
        </Row>
        {
          tile !== undefined && (
            <CustomModal show={true} onHide={() => setTile(undefined)} title={`Tile x: ${tile?.x} y: ${tile?.y}`}>
              <Row>
                <Col className='text-center'>
                  <h4>Type: {tile.type}</h4>
                  <h4>Production: {tile.productionType}</h4>
                  <h4>Guild: {tile.guild ? tile.guild : "NOT-SET"}</h4>
                  <h4>Owner: {tile.owner ? tile.owner : "NOT-SET"}</h4>
                  <h4>Outpost Level: {tile.outpostLevel}</h4>
                  {/* <p>{JSON.stringify(tile)}</p> */}
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 5
                  }}>
                    {
                      !userCastle && <Button className='custom-btn' onClick={() => handleDeploy(tile.x, tile.y)}>Deploy here</Button>
                    }
                    {
                      userCastle && isTileVisible() && <Button className='custom-btn' onClick={() => handleDeployOutpost(tile.x, tile.y)}>Deploy outpost</Button>
                    }
                    <Button className='custom-btn' onClick={() => setTile(undefined)}>Close</Button>
                  </div>
                </Col>
              </Row>
            </CustomModal>
          )
        }
      </div>
    </PageLayout>
  );
});