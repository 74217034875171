import React, { useEffect, useState, useCallback } from 'react';
import { LeaderboardItem } from './components/LeaderboardItem';
import { Timer } from '../../components/Timer/Timer';
import { fetchLeaderboard } from './hooks';
import { LeaderboardUser } from 'utils/types';
import { PageLayout } from 'components/PageLayout';
import { Col, Row, Table } from 'react-bootstrap';
import account from 'store/AccountStore';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

const Leaderboard: React.FC = () => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardUser[]>([]);
  const { address } = useGetAccountInfo();
  useEffect(() => {
    const fetchData = async () => {
      const leaderboardFetched = await fetchLeaderboard();
      setLeaderboard(leaderboardFetched);
    };
    fetchData();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col>
          <h1 className='text-center'>Leaderboard</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Timer />
          <p className='text-center'>This leaderboard is updated once every 30 minutes!</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table style={{ borderCollapse: 'collapse' }} striped responsive>
          <thead>
            <tr>
              <th>S2</th>
              <th>Ranks</th>
              <th>Wallet Address</th>
              <th>Score</th>
              <th>Reward Prize</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((user, index) => (
              <LeaderboardItem
                wallet={user.address}
                key={user.address}
                rank={index + 1}
                score={user.score}
                userWallet={address}
              />
            ))}
          </tbody>
        </Table>
        </Col>
      </Row>
    </PageLayout>
  );
};

export { Leaderboard };