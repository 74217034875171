import { FC, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { DisplaySft } from './DisplaySft';
import { observer } from 'mobx-react-lite';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { NftTypeWithQuantity } from 'utils/types';

export const DisplaySftsForRaid: FC<Props> = observer(({ sfts, onCloseModal, func, name = 'Raid', xs, sm, md, lg }) => {
  const [sftsSelected, setSftsSelected] = useState<NftTypeWithQuantity[]>([]);
  const { address } = useGetAccountInfo();

  const exists = (sft: NftTypeWithQuantity): boolean => {
    if(sftsSelected.some((sftSelected) => sftSelected.identifier === sft.identifier)) return true;
    return false;
  }

  const handleChange = (sft: NftTypeWithQuantity) => {
    if(exists(sft)) {
      setSftsSelected((prev) => prev.filter((sftSelected) => sftSelected.identifier !== sft.identifier))
      return;
    };
    setSftsSelected((prev) => [...prev, sft]);
  }

  const handleFight = () => {
    if(sftsSelected.length === 0) return;
    const sftsToUpdate = [];
    for(const sftSelected of sftsSelected) {
      const sftFound = sfts.find((sft) => sft.identifier === sftSelected.identifier);
      if(!sftFound) continue;
      sftsToUpdate.push(sftFound);
    }
    setSftsSelected(sftsToUpdate.filter((sftToUpdate) => sftToUpdate.quantity > 0))
    func(address, sftsSelected).then(() => onCloseModal())
  }

    return (
      <Row>
        <Col xs={12} className='mb-2'>
          <Button className='custom-btn d-block mx-auto' onClick={handleFight}>{name === 'Raid' ? 'Fight' : 'Start Mission'}</Button>
        </Col>
        {sfts.map((sft, idx) => (
          <Col key={idx} xs={xs || 12} sm={sm || 4} md={md || 3} lg={lg || 3} className='mb-2'>
            <DisplaySft sft={sft} onSelect={handleChange} exists={exists} />
          </Col>
        ))}
      </Row>
    );
  }
);

interface Props {
  sfts: any[];
  onCloseModal: () => void;
  func: (address: string, fighters: any[]) => Promise<void>;
  name?: string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}
