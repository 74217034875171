import axios from "axios"
import { API_URL, chestsAddress } from "config"

export const getUserTransactions = async (address: string) => {
    try {
        const { data } = await axios.get(
            `${API_URL}/accounts/${address}/transactions?sender=${address}&receiver=${chestsAddress}&withScResults=true&withLogs=true&status=success&function=buy`
            );
            
        return data;
    } catch (error) {
        console.log(error);
    }
}