import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface PrizeT {
    name: string;
    balance: string; // or number, depending on what the API returns
  }

const useGetRemainTokens = () => {
    const [prizest, setPrizest] = useState<PrizeT[]>([]);

  useEffect(() => {
    const fetchPrizest = async () => {
      try {
        const response = await axios.get('https://mvx-api.estar.games/accounts/erd1qqqqqqqqqqqqqpgq8a4d8t9nts7feplsrutgkz80rvm0m3m8wmfsk29k74/tokens'); // Replace with actual API endpoint
        const prizeData = response.data.map((item: { name: any; balance: any; }) => ({
          name: item.name,
          balance: Math.round(Number(item.balance) / 1e18).toString()
        }));
        setPrizest(prizeData);
      } catch (error) {
        console.error('Error fetching prize data:', error);
      }
    };

    fetchPrizest();
  }, []);

  return prizest;
};

export default useGetRemainTokens;
