import { AbiRegistry } from "@multiversx/sdk-core/out";

export const raidAbi = AbiRegistry.create({
    "name": "XCastleRaid",
    "constructor": {
        "inputs": [],
        "outputs": []
    },
    "endpoints": [
        {
            "name": "upgrade",
            "mutability": "mutable",
            "inputs": [],
            "outputs": []
        },
        {
            "name": "addAdmin",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [
                {
                    "name": "address",
                    "type": "Address"
                }
            ],
            "outputs": []
        },
        {
            "name": "removeAdmin",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [
                {
                    "name": "address",
                    "type": "Address"
                }
            ],
            "outputs": []
        },
        {
            "name": "addCollection",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [
                {
                    "name": "collection_identifier",
                    "type": "TokenIdentifier"
                }
            ],
            "outputs": []
        },
        {
            "name": "removeCollection",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [
                {
                    "name": "collection_identifier",
                    "type": "TokenIdentifier"
                }
            ],
            "outputs": []
        },
        {
            "name": "withdraw",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [
                {
                    "name": "identifier",
                    "type": "TokenIdentifier"
                },
                {
                    "name": "nonce",
                    "type": "u64"
                }
            ],
            "outputs": []
        },
        {
            "name": "fight",
            "mutability": "mutable",
            "payableInTokens": [
                "*"
            ],
            "inputs": [],
            "outputs": []
        },
        {
            "name": "distributeReward",
            "mutability": "mutable",
            "inputs": [],
            "outputs": []
        },
        {
            "name": "getAdmins",
            "mutability": "readonly",
            "inputs": [],
            "outputs": [
                {
                    "type": "variadic<Address>",
                    "multi_result": true
                }
            ]
        },
        {
            "name": "getCollections",
            "mutability": "readonly",
            "inputs": [],
            "outputs": [
                {
                    "type": "variadic<TokenIdentifier>",
                    "multi_result": true
                }
            ]
        },
        {
            "name": "getNonces",
            "mutability": "readonly",
            "inputs": [
                {
                    "name": "collection_identifier",
                    "type": "TokenIdentifier"
                }
            ],
            "outputs": [
                {
                    "type": "variadic<u64>",
                    "multi_result": true
                }
            ]
        },
        {
            "name": "getFighter",
            "mutability": "readonly",
            "inputs": [
                {
                    "name": "collection_identifier",
                    "type": "TokenIdentifier"
                },
                {
                    "name": "nonce",
                    "type": "u64"
                }
            ],
            "outputs": [
                {
                    "type": "Fighter"
                }
            ]
        },
        {
            "name": "getLastBossId",
            "mutability": "readonly",
            "inputs": [],
            "outputs": [
                {
                    "type": "u64"
                }
            ]
        },
        {
            "name": "getCurrentBoss",
            "mutability": "readonly",
            "inputs": [],
            "outputs": [
                {
                    "type": "Boss"
                }
            ]
        },
        {
            "name": "getBosses",
            "mutability": "readonly",
            "inputs": [],
            "outputs": [
                {
                    "type": "variadic<Boss>",
                    "multi_result": true
                }
            ]
        },
        {
            "name": "getBossAttacks",
            "mutability": "readonly",
            "inputs": [
                {
                    "name": "boss_id",
                    "type": "u64"
                }
            ],
            "outputs": [
                {
                    "type": "variadic<BossAttack>",
                    "multi_result": true
                }
            ]
        },
        {
            "name": "getUserReward",
            "mutability": "readonly",
            "inputs": [
                {
                    "name": "boss_id",
                    "type": "u64"
                },
                {
                    "name": "address",
                    "type": "Address"
                }
            ],
            "outputs": [
                {
                    "type": "BigUint"
                }
            ]
        },
        {
            "name": "getBossUsers",
            "mutability": "readonly",
            "inputs": [
                {
                    "name": "boss_id",
                    "type": "u64"
                }
            ],
            "outputs": [
                {
                    "type": "variadic<Address>",
                    "multi_result": true
                }
            ]
        },
        {
            "name": "getFighters",
            "mutability": "readonly",
            "inputs": [
                {
                    "name": "collection_identifier",
                    "type": "TokenIdentifier"
                }
            ],
            "outputs": [
                {
                    "type": "List<Fighter>"
                }
            ]
        },
        {
            "name": "pause",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [],
            "outputs": []
        },
        {
            "name": "unpause",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [],
            "outputs": []
        },
        {
            "name": "isPaused",
            "mutability": "readonly",
            "inputs": [],
            "outputs": [
                {
                    "type": "bool"
                }
            ]
        },
        {
            "name": "createBoss",
            "mutability": "mutable",
            "payableInTokens": [
                "*"
            ],
            "inputs": [
                {
                    "name": "attack",
                    "type": "BigUint"
                },
                {
                    "name": "hp",
                    "type": "BigUint"
                },
                {
                    "name": "element",
                    "type": "Element"
                },
                {
                    "name": "name",
                    "type": "bytes"
                },
                {
                    "name": "tier",
                    "type": "bytes"
                },
                {
                    "name": "image",
                    "type": "bytes"
                }
            ],
            "outputs": []
        },
        {
            "name": "removeBoss",
            "mutability": "mutable",
            "inputs": [],
            "outputs": []
        },
        {
            "name": "createFighter",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [
                {
                    "name": "identifier",
                    "type": "TokenIdentifier"
                },
                {
                    "name": "nonce",
                    "type": "u64"
                },
                {
                    "name": "attack",
                    "type": "u64"
                },
                {
                    "name": "hp",
                    "type": "u64"
                },
                {
                    "name": "m_attack",
                    "type": "u64"
                },
                {
                    "name": "m_hp",
                    "type": "u64"
                },
                {
                    "name": "element",
                    "type": "Element"
                }
            ],
            "outputs": []
        },
        {
            "name": "removeFighter",
            "onlyOwner": true,
            "mutability": "mutable",
            "inputs": [
                {
                    "name": "identifier",
                    "type": "TokenIdentifier"
                },
                {
                    "name": "nonce",
                    "type": "u64"
                }
            ],
            "outputs": []
        }
    ],
    "types": {
        "Boss": {
            "type": "struct",
            "fields": [
                {
                    "name": "id",
                    "type": "u64"
                },
                {
                    "name": "attack",
                    "type": "BigUint"
                },
                {
                    "name": "hp",
                    "type": "BigUint"
                },
                {
                    "name": "element",
                    "type": "Element"
                },
                {
                    "name": "prize_pool",
                    "type": "EsdtTokenPayment"
                },
                {
                    "name": "name",
                    "type": "bytes"
                },
                {
                    "name": "tier",
                    "type": "bytes"
                },
                {
                    "name": "image",
                    "type": "bytes"
                }
            ]
        },
        "BossAttack": {
            "type": "struct",
            "fields": [
                {
                    "name": "address",
                    "type": "Address"
                },
                {
                    "name": "attack",
                    "type": "BigUint"
                }
            ]
        },
        "Element": {
            "type": "enum",
            "variants": [
                {
                    "name": "NULL",
                    "discriminant": 0
                },
                {
                    "name": "WIND",
                    "discriminant": 1
                },
                {
                    "name": "LIGHT",
                    "discriminant": 2
                },
                {
                    "name": "FIRE",
                    "discriminant": 3
                },
                {
                    "name": "DARK",
                    "discriminant": 4
                },
                {
                    "name": "EARTH",
                    "discriminant": 5
                },
                {
                    "name": "WATER",
                    "discriminant": 6
                }
            ]
        },
        "EsdtTokenPayment": {
            "type": "struct",
            "fields": [
                {
                    "name": "token_identifier",
                    "type": "TokenIdentifier"
                },
                {
                    "name": "token_nonce",
                    "type": "u64"
                },
                {
                    "name": "amount",
                    "type": "BigUint"
                }
            ]
        },
        "Fighter": {
            "type": "struct",
            "fields": [
                {
                    "name": "nonce",
                    "type": "u64"
                },
                {
                    "name": "attack",
                    "type": "u64"
                },
                {
                    "name": "hp",
                    "type": "u64"
                },
                {
                    "name": "m_attack",
                    "type": "u64"
                },
                {
                    "name": "m_hp",
                    "type": "u64"
                },
                {
                    "name": "element",
                    "type": "Element"
                }
            ]
        }
    }
}
)