import { Sword } from 'assets/icons'
import React, { FC, useEffect, useState } from 'react'
import { getBestPlayer } from '../hooks/getBestPlayer'
import { BossAttack } from 'utils/types/BossAttack'
import { PlayerAttack } from 'utils/types'

interface Props {
  attacks: PlayerAttack[]
}

const BestPlayer: FC<Props> = ({ attacks }) => {
    const [bestPlayer, setBestPlayer] = useState<BossAttack>();
    useEffect(() => {
        getBestPlayer(attacks).then((attack) => setBestPlayer(attack))
    }, [attacks])
    
  return (
    <div className="mission-card" style={{ position: 'relative' }}>
        <div className="mission-card-item-id">
            <h3>Best Player</h3>
        </div>
        <img
            src={require(`../../../assets/img/raid-best-player.jpeg`)}
            alt="raid"
            width={'100%'}
            className='border-radius-20'
        />
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '20px',
              }}>
                ADDRESS:
              </span>
              <span className='text-white' style={{
                fontSize: '20px',
              }}>{bestPlayer?.address.slice(0, 6)}...{bestPlayer?.address.slice(-6)}</span>
            </div>
            <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '20px',
              }}>
                ATTACK:
              </span>
              <span className='text-white' style={{
                fontSize: '20px',
              }}>{(Number(bestPlayer?.attack) / 1000).toLocaleString()}</span>
              <Sword width={22} height={22} fill='white' />
            </div>
        </div>
    </div>
  )
}

export default BestPlayer
