import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Row } from 'react-bootstrap'
import account from 'store/AccountStore'
import { claimTransaction } from 'utils/transactions'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { getDailyProduction } from '../hooks/getDailyProduction'

interface Props {
    address?: string
}

const Production: FC<Props> = observer(({ address }) => {
    const { address: current_address } = useGetAccountInfo();
    const [disable, setDisable] = useState(true);
    const [production, setProduction] = useState<any[]>([]);
    const { rewards } = account;

    useEffect(() => {
        if(!address) return

        getDailyProduction(address).then((data) => setProduction(data))

        if(current_address !== address) return
        account.loadRewards(address)
    }, [address])

    useEffect(() => {
        if(current_address !== address) return
        if(rewards.length === 0) return
        rewards.map((reward) => {
            if(reward.amount > 0) {
                setDisable(false);
                return;
            }
        })
    }, [rewards])

  return (
    <div className='mission-card pb-4'>
            <h1 className='text-center fw-600 profile-badge mb-3'>Production</h1>
            <Row>
                <Col className='text-center'>
                        <h3 className='fw-600 text-underline'>DAILY</h3>
                        {
                            production.map((resource) => (
                                <h4 key={resource.name} className='text-uppercase'>{resource.amount} {resource.name}</h4>
                            ))
                        }
                </Col>
                {
                    current_address === address && (
                        <Col className='text-center'>
                        <h3 className='fw-600 text-underline'>CLAIMABLE</h3>
                        {
                            rewards.map((reward) => (
                                <h4 key={reward.name} className='text-uppercase'>{(reward.amount / Math.pow(10, 18)).toLocaleString()} {reward.name}</h4>
                            ))
                        }
                </Col>
                    )
                }
            </Row>
            {
                current_address === address && (
                    <Row className='mb-2'>
                        <Col>
                            <button className='d-block m-auto btn custom-btn' disabled={disable} onClick={() => claimTransaction()}>Claim</button>
                        </Col>
                    </Row>
                )
            }
          </div>
  )
})

export default Production