import axios from "axios"
import { missionAddress } from "config"

export const getUserMissions = async (address: string) => {
    try {
        const { data } = await axios.get(
            `https://mvx-api.estar.games/accounts/${address}/transactions?size=10000&sender=${address}&receiver=${missionAddress}&status=success&function=goToMission`
            )
        return data.length;
    } catch (error) {
        return 0;
    }
} 