import { Address } from '@multiversx/sdk-core/out'
import { sendTransactions } from '@multiversx/sdk-dapp/services'
import { refreshAccount } from '@multiversx/sdk-dapp/utils'
import { COLLECTION_IDENTIFIER, chestsAddress } from 'config'
import { Dispatch, SetStateAction, useState } from 'react'
import { numberToHex, stringToHex } from 'ts-apc-utils'

export const useSendOpenChestTransaction = (): { sendOpenChestTransaction: (address: string, amount: number) => Promise<void>, transactionSessionId: string, setTransactionSessionId: Dispatch<SetStateAction<string>> } => {
  const [transactionSessionId, setTransactionSessionId] = useState<string>(
    ''
  )

  const sendOpenChestTransaction = async (address: string, amount: number): Promise<void> => {
    const transaction = {
      value: 0,
      gasLimit: '15000000',
      data:
        'ESDTNFTTransfer@' +
        stringToHex(COLLECTION_IDENTIFIER) +
        '@' +
        numberToHex(1) +
        '@' +
        numberToHex(amount) +
        '@' +
        new Address(chestsAddress).hex() +
        '@' +
        stringToHex('buy') +
        '@' +
        numberToHex(amount),
      receiver: address
    }
    await refreshAccount()

    const { sessionId } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occured during',
        successMessage: 'Transaction successful'
      },
      redirectAfterSign: false
    })
    if (sessionId != null) {
      setTransactionSessionId(sessionId)
    }
  }

  return { sendOpenChestTransaction, transactionSessionId, setTransactionSessionId }
}