import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { missionAddress } from '../../config';
import { numberToHex } from 'muf-utils';

export const endMissionTransaction = async (id: number) => {
    const mission = {
        value: 0,
        data: `endMission@${numberToHex(id)}`,
        receiver: missionAddress,
        gasLimit: 30000000
      };
      await refreshAccount();
    
      await sendTransactions({
        transactions: mission,
        transactionsDisplayInfo: {
          processingMessage: 'Processing transaction',
          errorMessage: 'An error has occured',
          successMessage: 'transaction successful'
        },
        redirectAfterSign: false
      });
}