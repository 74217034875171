import { Address } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { craftAddress, TOKEN_DECIMALS, TOKEN_IDENTIFIER } from '../../config';
import { numHex } from '../functions/numHex';
export const craftItemTransaction = async ({
  sender,
  prices,
  amount,
  id
}: {
  sender: string;
  prices: any[];
  amount: number;
  id: string;
}) => {
  let data = 'MultiESDTNFTTransfer@' + new Address(craftAddress).hex() + '@' + numHex(prices.length);
  console.log(prices);
  for(const price of prices) {
    data += '@' + Buffer.from(price.token_identifier).toString('hex') + '@' + numHex(price.token_nonce) +  '@' + numHex(price.amount * amount);
  }

  data += '@' + Buffer.from('craftItem').toString('hex') + '@' + numHex(Number(id)) + '@' + numHex(amount)

  const craftItem = {
    value: 0,
    data,
    receiver: sender,
    gasLimit: 20000000
  };
  await refreshAccount();

  await sendTransactions({
    transactions: craftItem,
    transactionsDisplayInfo: {
      processingMessage: 'Processing transaction',
      errorMessage: 'An error has occured',
      successMessage: 'transaction successful'
    },
    redirectAfterSign: false
  });
};
