import React, { useEffect, useState } from 'react';

import './index.css';

const Timer: React.FC = () => {
  const [time, setTime] = useState<number>(0);

  const calculateTimeLeft = (): number => {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
    const timeLeft = Math.floor((endOfMonth.getTime() - now.getTime()) / 1000);
    return timeLeft;
  };

  useEffect(() => {
    setTime(calculateTimeLeft());

    const timerId = setInterval(() => {
      setTime(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const formatTime = (time: number) => {
    const days = Math.floor(time / (60 * 60 * 24));
    const hours = Math.floor((time % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((time % (60 * 60)) / 60);
    const seconds = Math.floor(time % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return <h4 className='timer'>Time left till rewards: {formatTime(time)}</h4>;
};

export { Timer };