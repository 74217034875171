import { NftType } from '@multiversx/sdk-dapp/types/tokens.types'
import { getUserStats } from 'pages/Raid/hooks/getUserStats'
import React, { FC, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getUserCharacters } from '../hooks/getUserCharacters'

interface Props {
  address?: string
}

const Army: FC<Props> = ({ address }) => {
  const [characters, setCharacters] = useState<NftType[]>([]);
  const [stats, setStats] = useState({
    attack: 0,
    m_attack: 0,
    hp: 0,
    m_hp: 0,
  });

  useEffect(() => {
    if(!address) return
    getUserCharacters(address).then((data) => setCharacters(data))
  }, [address])

  useEffect(() => {
    if(!address) return
    if(characters.length === 0) return

    getUserStats(characters).then((data) => setStats(data))
  }, [address, characters])

  return (
    <div className='mission-card'>
        <Row>
            <Col className='text-center'>
                <h1 className='text-center fw-600 profile-badge mb-3'>Army</h1>
                <h4>DEFENSE: {stats.hp}</h4>
                <h4>ATTACK: {stats.attack}</h4>
                <h4>DEFENSE MULTIPLIER:: {stats.m_hp}</h4>
                <h4>ATTACK MULTIPLIER: {stats.m_attack}</h4>
            </Col>
        </Row>
    </div>
  )
}

export default Army