import React, { FC, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getUserChestsOpened } from '../hooks/getUserChestsOpened';
import { getUserMissions } from '../hooks/getUserMissions';
import { getUserRaids } from '../hooks/getUserRaids';

interface Props {
  address?: string
}

const Achievements: FC<Props> = ({ address }) => {
  const [missions, setMisssions] = useState(0);
  const [raids, setRaids] = useState(0);
  const [chests, setChests] = useState(0);

  useEffect(() => {
    if(!address) return
    getUserMissions(address).then((data) => setMisssions(data))
    getUserRaids(address).then((data) => setRaids(data))
    getUserChestsOpened(address).then((data) => setChests(data))
  }, [address])
  return (
    <div className='mission-card pb-3 pl-3 pr-3'>
            <h2 className='text-center fw-600 profile-badge mb-3'>Achievements</h2>
            <Row>
              <Col className='text-center'>
                  <h4 className='text-center'>Chests</h4>
                  <img
                    src="https://cdn.midjourney.com/34ab26be-e0f1-4c41-b37c-fc430e18f0f1/0_0.webp"
                    width={100}
                    alt="badge"
                    style={{
                      borderRadius: '12px'
                    }}
                  />
                  <h5>x{chests}</h5>
              </Col>
              <Col className='text-center'>
                  <h4 className='text-center'>Missions</h4>
                  <img
                    src="https://cdn.midjourney.com/5f14b8bd-6380-4789-aa9d-4381eb2bddf1/0_3.webp"
                    width={100}
                    alt="badge"
                    style={{
                      borderRadius: '12px'
                    }}
                  />
                  <h5>x{missions}</h5>
              </Col>
              <Col className='text-center'>
                  <h4 className='text-center'>Raids</h4>
                  <img
                    src="https://cdn.midjourney.com/5f14b8bd-6380-4789-aa9d-4381eb2bddf1/0_2.webp"
                    width={100}
                    alt="badge"
                    style={{
                      borderRadius: '12px'
                    }}
                  />
                  <h5>x{raids}</h5>
              </Col>
              <Col className='text-center'>
                  <h4 className='text-center'>King</h4>
                  <img
                    src="https://cdn.midjourney.com/5f14b8bd-6380-4789-aa9d-4381eb2bddf1/0_0.webp"
                    width={100}
                    alt="badge"
                    style={{
                      borderRadius: '12px'
                    }}
                  />
                  <h5>x0</h5>
              </Col>
              <Col className='text-center'>
                  <h4 className='text-center'>Outposts</h4>
                  <img
                    src="https://cdn.midjourney.com/dd58b104-f0b1-4649-bc64-250404b734a1/0_0.webp"
                    width={100}
                    alt="badge"
                    style={{
                      borderRadius: '12px'
                    }}
                  />
                  <h5>x0</h5>
              </Col>
            </Row>
          </div>
  )
}

export default Achievements