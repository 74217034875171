import React, { FC, useEffect, useState } from 'react'
import { PageLayout } from 'components/PageLayout'
import { Button, Card, Col, Row } from 'react-bootstrap';
import chestImg from 'assets/img/chest.png'
import keyImg from 'assets/img/key_mini.webp'
import { observer } from 'mobx-react-lite'
import account from 'store/AccountStore';
import { useSendOpenChestTransaction } from './hooks/useSendOpenChestTransaction'
import CardResult from './components/CardResult'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { DeadlineTimer } from 'components'
import { Link } from 'react-router-dom'
import { routeNames } from '../../routes';
import TransactionsLog from './components/TransactionsLog';
import useGetRemainPrizes from './hooks/getRemainPrizes';
import useGetRemainTokens from './hooks/getRemainTokens';


export const Chests: FC = observer(() => {
  const keys = account.getKeys().length;
  const { address } = useGetAccountInfo();
  const deadline = 1711918799;
  const [isOver, setIsOver] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);

  const { sendOpenChestTransaction, transactionSessionId, setTransactionSessionId } = useSendOpenChestTransaction();
  const prizes = useGetRemainPrizes();
  const prizest = useGetRemainTokens();
  const updateStatus = () => {
    setIsOver(false);
  }

  useEffect(() => {
    const current_time = Date.now() / 1000;
    if(deadline <= current_time) updateStatus();
  }, [])

  const handleIncrease = () => {
    if(keys > amount || amount >= 5) return
    setAmount((prev) => prev + 1);
  }

  const handleDecrease = () => {
    if(amount <= 1) return
    setAmount((prev) => prev - 1);
  }

  return (
    <PageLayout>
      <Row className='mb-3'>
        <Col>
          <h1 className='text-center'>Chests</h1>
        </Col>
      </Row>
      <Row className='mb-2' style={{ background: '#F5DECC', padding: '30px' }}>
        <Col xs={12}>
          <h3 className='text-center'>Time left:</h3>
          <h3 className='text-center'><DeadlineTimer deadline={deadline} overText='The chests are closed!' onlyTime={false} overFunc={updateStatus} /></h3>
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <Card>
            <Card.Body className='d-flex flex-column'>
              <Card.Title className='text-center'>Open Chests</Card.Title>
              <Card.Img src={chestImg} style={{ maxWidth: '300px', width: '100%' }} />
              <div className='d-flex align-items-center justify-content-center'>
                <button className="btn custom-btn" onClick={handleDecrease}>-</button>
                <span className='mx-2' style={{ fontSize: '17px' }}>{amount}</span>
                <button className="btn custom-btn" onClick={handleIncrease}>+</button>
              </div>
              <Button
                variant={'secondary'}
                // disabled={keys === 0 || isOver}
                disabled={false}
                className='muted mt-3 mx-auto p-2 d-flex align-items-center'
                onClick={() => sendOpenChestTransaction(address, amount)}
              >
                <span style={{ fontSize: '15px', fontWeight: 800, color: 'white' }}>{amount}</span>
                <img src={keyImg} className='ml-2' style={{ width: '100%', maxWidth: '50px' }} />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className='mission-card-info py-3 px-4 mt-3' xs={12}>
            <div className="mission-card-info-content flex-column flex-md-row mx-3">
                <FontAwesomeIcon icon={faInfo} color="#703104" fontSize={50} className='mr-md-4 mb-3 mb-md-0' />
                <p>
                  <strong>Chest Rewards System:</strong><br></br>
                  <br></br>
                  To participate in the Chest Rewards System,
                  send an{' '}
                  <Link to={routeNames.bazaar} style={{color: 'unset', textDecoration: 'underline'}}>Elrond Castle Key</Link>{' '}
                  (Keys are available for purchase in the{' '}
                  <Link to={routeNames.bazaar} style={{color: 'unset', textDecoration: 'underline'}}>Shop</Link>).
                  The system assigns you a random number corresponding to a specific prize upon sending.<br></br>
                  <br></br>
                  You will receive an "unlucky day" message if your assigned number correlates with a 
                  depleted prize category. This doesn't mean all rewards are gone, but your specific draw was unlucky.<br></br>
                  <br></br>
                  Just so you know, the Chest is open for participation only for a limited duration. In each round, we 
                  will specify the potential rewards up for grabs.<br></br>
                  <br></br>
                  <div>
                  <strong>Rewards available in the chest:</strong><br></br>
                  NFT Prizes:
                  <hr></hr>
                  {prizes.map((prize, index) => (
                    <div key={index}>
                      - {prize.balance} {prize.name}
                    </div>
                  ))}
                  <br></br>
                  Token Prizes:
                  <hr></hr>
                   {prizest.map((prizet, index) => (
                    <div key={index}>
                      - {prizet.balance} {prizet.name}
                    </div>
                  ))}
                </div>
                    
                </p>
            </div>
        </Col>
      </Row>
      <TransactionsLog />
      <CardResult sessionId={transactionSessionId} setSessionId={setTransactionSessionId} />
    </PageLayout>
  )
})
