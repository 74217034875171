import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { PageLayout } from 'components/PageLayout'
import { Col, Row } from 'react-bootstrap'
import { UserActiveMission } from './components/UserActiveMission'
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks'
import account from 'store/AccountStore'
import { ListOfMissions } from './components/ListOfMissions'
import MissionLog from './components/MissionLog'
import useMission from './hooks/useMission'
import { endMissionTransactionOld } from 'utils/transactions/sendEndMissionOld'

export const Mission: FC = observer(() => {
  const { address } = useGetAccountInfo()
  const { activeMissions } = account;
  const { missions, loading } = useMission();
  const { fail, success } = useGetActiveTransactionsStatus()
  
  useEffect(() => {
    account.loadActiveMission(address)
    account.loadSFTsOnChain(address)
  }, [address, fail, success])

  return (
    <PageLayout>
      <Row className='mb-3'>
        <Col>
          <h1 className='text-center'>Missions</h1>
          <button className='btn custom-btn d-block mx-auto' onClick={endMissionTransactionOld}>End Old Mission</button>
        </Col>
      </Row>
      {
        activeMissions.map((mission) => (
          <UserActiveMission key={mission.id} activeMission={mission} />
        ))
      }
      <ListOfMissions loading={loading} missions={missions} />
      <MissionLog />
    </PageLayout>
  )
})