import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'

const CustomModal: FC<Props> = ({ show, onHide, title, children, capitalize }) => {
  return (
    <Modal show={show} onHide={onHide} size='xl'>
        <Modal.Header className='align-items-center'>
            <Modal.Title className={`${capitalize ? 'text-capitalize' : null}`}>{title}</Modal.Title>
            <FontAwesomeIcon icon={faTimes} fontSize={20} cursor={'pointer'} onClick={onHide} />
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
    </Modal>
  )
}

interface Props {
    show: boolean
    onHide: () => void
    title: string
    children: React.ReactNode
    capitalize?: boolean
}

export default CustomModal