import React, { FC } from 'react'
import { sftsRewards } from 'config';
import { getTokenIcon } from 'utils/functions/getTokenIcon';

const SFTResourceList: FC<Props> = ({ nonce }) => {
const sftReward = sftsRewards.find((sft_nonce) => sft_nonce.nonce === Number(nonce));
  return (
    <div className="d-flex flex-column">
        {sftReward?.resources.map((resource, index) => (
            <div className="d-flex align-items-center" key={index}>
                <h5 className='pt-1 mr-1'>{resource.amount}</h5>
                {getTokenIcon(resource.type.toUpperCase())}
            </div>
        ))}
    </div>
  )
}

interface Props {
    nonce: number
}

export default SFTResourceList