import axios from "axios";
import { MICROSERVICE_URL } from "config";
import { SFTStaked } from "utils/types";

export const fetchSftsStaked = async (address: string): Promise<SFTStaked[]> => {
    try {
      const { data } = await axios.get(
        MICROSERVICE_URL + '/stake/users/' + address
      );
      return data;
    } catch (err) {
      return [];
    }
  }