import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Row, Table } from 'react-bootstrap'
import { Boss } from 'utils/types'
import { getBosses } from '../hooks/getBosses'
import BigNumber from 'bignumber.js';

interface Props {
    boss_id?: number
}

const Bosses: FC<Props> = observer(({ boss_id }) => {
    const [bosses, setBosses] = useState<Boss[]>([]);

    useEffect(() => {
        getBosses().then((data) => setBosses(data.filter((boss) => boss.id !== boss_id)))
    }, [boss_id])
    return (
        <>
            <Row className='mt-3'>
                <Col xs={12}>
                    <h1 className='text-center'>Boss History</h1>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    <Table className='mission-log' responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Attack</th>
                                <th>HP</th>
                                <th>Reward</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bosses.map((boss, index) => (
                                <tr key={index}>
                                    <td>{boss.id}</td>
                                    <td>{(Number(boss.attack) / 1000).toLocaleString()}</td>
                                    <td>{(Number(boss.hp) / 1000).toLocaleString()}</td>
                                    <td>{
                                        new BigNumber(boss.prize_pool.amount)
                                            .div(Math.pow(10, 18))
                                            .toNumber()
                                            .toLocaleString()
                                        }{" "}
                                        <img
                                            src={`https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/${boss.prize_pool.identifier}/logo.svg`}
                                            width={22}
                                            height={22}
                                            alt={boss.prize_pool.identifier}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
})

export default Bosses