import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ProgressBar } from 'react-bootstrap'
import { missionsTime } from 'config';

export const MissionProgress = observer(({ id, deadline, setDeadlineReached }: { id: number, deadline: number, setDeadlineReached: any }) => {
    const [progress, setProgress] = useState(0);
    const missionTime = missionsTime.find((mission) => mission.id === id);
    
    useEffect(() => {
      if(!missionTime) return;
      const calculateProgress = () => {
          const currentTime = Math.floor(Date.now() / 1000);
          const totalDuration = deadline - (deadline - missionTime.time);
          const elapsedDuration = currentTime - (deadline - missionTime.time);

          const progressPercentage = Math.floor(Math.min((elapsedDuration / totalDuration) * 100, 100));
          setProgress(progressPercentage);
      };
      const updateProgress = setInterval(calculateProgress, 1000);

      return () => clearInterval(updateProgress);
    }, [deadline]);

    useEffect(() => {
      if(progress === 100) {
        setDeadlineReached(true);
      }
    }, [progress])

  return (
    <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
            <h5>{progress}%</h5>
            <h5>100%</h5>
        </div>
        <ProgressBar now={progress} className='mission-card-box-left-progress' />
    </div>
  )
})
