import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Prize {
    name: string;
    balance: string; // or number, depending on what the API returns
  }

const useGetRemainPrizes = () => {
    const [prizes, setPrizes] = useState<Prize[]>([]);

 

  useEffect(() => {
    const fetchPrizes = async () => {
      try {
        const response = await axios.get('https://mvx-api.estar.games/accounts/erd1qqqqqqqqqqqqqpgq8a4d8t9nts7feplsrutgkz80rvm0m3m8wmfsk29k74/nfts');
        const prizeData = response.data
          .filter((item: { name: string; }) => item.name !== 'Elrond Castle Key')
          .map((item: { name: string; balance: string; }) => ({
            name: item.name,
            balance: item.balance
          }));
        setPrizes(prizeData);
      } catch (error) {
        console.error('Error fetching prize data:', error);
      }
    };
  
    fetchPrizes();
  }, []);
  

  return prizes;
};

export default useGetRemainPrizes;
