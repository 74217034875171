export const getRewardPrize = (rank: number): number => {
    switch (rank) {
      case 1:
        return 40000;
      case 2:
        return 25000;
      case 3:
        return 15000;
      case 4:
      case 5:
      case 6:
      case 7:
        return 7000;
      case 8:
      case 9:
      case 10:
        return 5000;
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
        return 3500;
      case 21:
      case 22:
      case 23:
      case 24:
      case 25:
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
        return 2500;
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
      case 36:
      case 37:
      case 38:
      case 39:
      case 40:
        return 1500;
      default:
        return 0;
    }
  };
