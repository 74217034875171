export enum TileType {
    GRASS = "GRASS",
    WATER = "WATER",
    MOUNTAIN = "MOUNTAIN",
    HILL = "HILL",
    ROCK = "ROCK",
    FOREST = "FOREST",
    OUTPOST = "OUTPOST",
    CASTLE = "CASTLE",
    CORNER = "CORNER",
    RELIC1 = "RELIC1",
    RELIC2 = "RELIC2",
    RELIC3 = "RELIC3",
    RELIC4 = "RELIC4",
    RELIC5 = "RELIC5",
    RELIC6 = "RELIC6",
    RELIC7 = "RELIC7",
    RELIC8 = "RELIC8",
  }