import { BossAttack } from "utils/types/BossAttack";

export const getBestPlayer = async (attacks: BossAttack[]): Promise<BossAttack | undefined> => {
    if(attacks.length === 0) return undefined

    let maxAttack = attacks[0];

    for (let i = 1; i < attacks.length; i++) {
        if (parseInt(attacks[i].attack) > parseInt(maxAttack.attack)) {
            maxAttack = attacks[i];
        }
    }
    return maxAttack;
}