import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import account from '../store/AccountStore';
import { Col, Card } from 'react-bootstrap';
import { DisplaySftsInfo } from './DisplaySftsInfo';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';

export const DisplaySfts = observer((props: PropsType) => {
  const { sfts } = account;



  return (
    <>
      {sfts.map((sft) => (
        <Col sm={props.sm} md={props.md} key={sft.identifier} className='mb-2'>
          <DisplaySft sft={sft} func={props.func}/>
        </Col>
      ))}
    </>
  );
});

const DisplaySft: FC<PropSft> = ({sft, func}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

    return (
      <Card onClick={() => func(sft)}>
                <Card.Img
                  src={sft.url}
                  style={{ borderRadius: '10px 10px 0 0' }}
                >
                </Card.Img>
                <Card.Header className='d-flex justify-content-between'>
                  <span>{sft.name}</span>
                  <span>x{sft.balance}</span>
                  <div onClick={openModal} className="info-icon">?</div>
                    <DisplaySftsInfo nonce={sft.nonce} showModal={isModalOpen} closeModal={closeModal} />
                </Card.Header>
              </Card>
    )

}

interface PropSft{
  sft: NftType;
  func: (arg1: any) => void;
}


type PropsType = {
  xs?: number;
  sm?: number;
  md?: number;
  func: (arg1: any) => void;
};
