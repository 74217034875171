import { useEffect, useState } from 'react';
import { Nav, NavItem, Navbar, Container } from 'react-bootstrap';
import {
  faBoxOpen,
  faSignsPost,
  faCartShopping,
  faList,
  faPager,
  faWallet,
  faBook,
  faJedi,
  faShield,
  faCompassDrafting,
  faCrown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routeNames } from 'routes';
import { Link, useLocation } from 'react-router-dom';
import { useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';

const subNavItems = [
  
  {
    name: 'Map',
    label: 'map',
    link: routeNames.map,
    icon: <FontAwesomeIcon icon={faSignsPost} />
  },
  {
    name: 'Bazaar',
    label: 'bazzar',
    link: routeNames.bazaar,
    icon: <FontAwesomeIcon icon={faCartShopping} />
  },
  {
    name: 'Missions',
    label: 'missions',
    link: routeNames.missions,
    icon: <FontAwesomeIcon icon={faShield} />
  },
  {
    name: 'Leaderboard',
    label: 'leaderboard',
    link: routeNames.leaderBoard,
    icon: <FontAwesomeIcon icon={faList} />
  },
  {
    name: 'Market',
    label: 'secondary-market',
    link: 'https://xoxno.com/collection/XCASTLE-ee733b',
    icon: <FontAwesomeIcon icon={faPager} />
  },
  {
    name: 'Chests',
    label: 'chests',
    link: routeNames.chests,
    icon: <FontAwesomeIcon icon={faBoxOpen} />
  },
  {
    name: 'Profile',
    label: 'profile',
    link: routeNames.profile,
    icon: <FontAwesomeIcon icon={faCrown} />
  },
  {
    name: 'Raid',
    label: 'raid',
    link: routeNames.raid,
    icon: <FontAwesomeIcon icon={faJedi} />
  },
];

export const SubMenu = () => {
  const [activeItem, setActiveItem] = useState<string>(subNavItems[0].label);
  const isLoggedIn = useGetIsLoggedIn();
  const { address } = useGetAccountInfo();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.length > 10) {
      setActiveItem(pathname.slice(11));
    } else {
      setActiveItem(subNavItems[0].label);
    }
  }, [pathname]);

  if (!isLoggedIn) return null;

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand className='d-block d-lg-none'>Menu</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
          <Nav className='mt-2 mt-lg-0'>
            {subNavItems.map((subNavItem) => (
              <NavItem key={subNavItem.name} className={'mb-3 mb-lg-0'}>
                {subNavItem.label !== 'secondary-market' ? (
                  <Link
                    className={`subNavItem-link ${
                      activeItem === subNavItem.label
                        ? 'active-subNavItem'
                        : undefined
                    }`}
                    to={subNavItem.label === 'profile' ? `/dashboard/profile/${address}` : subNavItem.link}
                    onClick={() => setActiveItem(subNavItem.label)}
                  >
                    <span>{subNavItem.icon} &nbsp; {subNavItem.name}</span>
                  </Link>
                ) : (
                  <a
                    href={subNavItem.link}
                    target='_blank'
                    className='subNavItem-link'
                  >
                    <span>{subNavItem.icon} &nbsp; {subNavItem.name}</span>
                  </a>
                )}
              </NavItem>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
