import { Beer, Bread, Eccu, Iron, Shield, Stone, Wood } from "assets/icons"
import { EgldLogo, EstarLogo, VegldLogo, OuroLogo, GSCLogo, SSCLogo } from "assets/img";

export const getTokenIcon = (token: string, width?: string, height?: string, rest?: any) => {
    
    switch (token) {
        case 'ECCU':
            return <Eccu width={width ? width : '18px'} {...rest} />;
        case 'FOOD':
            return <Bread width={width ? width : '18px'} {...rest} />;
        case 'BEER':
            return <Beer width={width ? width : '18px'} {...rest} />;
        case 'WOOD':
            return <Wood width={width ? width : '20px'} {...rest} />;
        case 'STONE':
            return <Stone width={width ? width :'18px'} {...rest} />;
        case 'IRON':
            return <Iron width={width ? width : '22px'} {...rest} />;
        case 'WARGEAR':
            return <Shield width={width ? width : '14px'} {...rest} />;
        case 'ESTAR':
            return <EstarLogo width={width ? width : '30px'} height={height ? height : '30px'} {...rest} />;
        case 'EGLD':
            return <EgldLogo width={width ? width : '14px'} {...rest} />;
        case 'VEGLD':
            return <VegldLogo width={width ? width : '20px'} height={height ? height : '20px'} {...rest} />;
        case 'OURO':
            return <OuroLogo width={width ? width : '20px'} height={height ? height : '20px'} {...rest} />;
        case 'GSC':
            return <GSCLogo width={width ? width : '20px'} height={height ? height : '20px'} {...rest} />;
        case 'SSC':
            return <SSCLogo width={width ? width : '20px'} height={height ? height : '20px'} {...rest} />;
                   
            default:
            break;
    }
}