import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import axios from 'axios';
import { API_URL, COLLECTION_IDENTIFIER, MICROSERVICE_URL, missionAddress } from '../../config';
import { ActiveMission, NftTypeWithQuantity, Reward, SFTStaked, UserMission } from '../../utils/types';
import { removeDecimals } from 'utils/functions/removeDecimals';
class AccountService {
  constructor() {}

  async getEstarBalance(address: string): Promise<number> {
    try {
      const { data } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/ESTAR-461bab'
      );
      return data.balance;
    } catch (err) {
      return 0;
    }
  }

  async getEccuBalance(address: string): Promise<number> {
    try {
      const { data } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/ECCU-29891f'
      );
      return data.balance;
    } catch (err) {
      return 0;
    }
  }

  async fetchSfts(address: string): Promise<NftTypeWithQuantity[]> {
    try {
      const { data } = await axios.get(
        API_URL +
          '/accounts/' +
          address +
          '/nfts?collection=' +
          COLLECTION_IDENTIFIER + '&size=150'
      );
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async fetchSftsStaked(address: string): Promise<SFTStaked[]> {
    try {
      const { data } = await axios.get(
        MICROSERVICE_URL + '/stake/users/' + address
      );
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async fetchRewards(address: string): Promise<Reward[]> {
    try {
      const { data } = await axios.get(
        MICROSERVICE_URL + '/stake/users/' + address + '/rewards'
      );
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async getFoodBalance(address: string): Promise<number> {
    try {
      const { data }: { data: { balance: number } } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/FOOD-46261d'
      );
      return Number(removeDecimals(data.balance, 18).toFixed(2));
    } catch (err) {
      return 0;
    }
  }

  async getWoodBalance(address: string): Promise<number> {
    try {
      const { data } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/WOOD-2f70ee'
      );
      return Number(removeDecimals(data.balance, 18).toFixed(2));
    } catch (err) {
      return 0;
    }
  }

  async getBeerBalance(address: string): Promise<number> {
    try {
      const { data } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/BEER-093bf2'
      );
      return Number(removeDecimals(data.balance, 18).toFixed(2));
    } catch (err) {
      return 0;
    }
  }

  async getIronBalance(address: string): Promise<number> {
    try {
      const { data } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/IRON-abe3cd'
      );
      return Number(removeDecimals(data.balance, 18).toFixed(2));
    } catch (err) {
      return 0;
    }
  }

  async getStoneBalance(address: string): Promise<number> {
    try {
      const { data } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/STONE-6830a4'
      );
      return Number(removeDecimals(data.balance, 18).toFixed(2));
    } catch (err) {
      return 0;
    }
  }

  async getWargearBalance(address: string): Promise<number> {
    try {
      const { data } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/WARGEAR-932f1d'
      );
      return Number(removeDecimals(data.balance, 18).toFixed(2));
    } catch (err) {
      return 0;
    }
  }

  async fetchActiveMission(address: string): Promise<ActiveMission[]> {
    try {
      const { data } = await axios.get(
        MICROSERVICE_URL + '/missions/' + address
      );
      return data;
    } catch (err) {
      return [];
    }
  }

  async fetchMissionsHistory(address: string): Promise<UserMission[]> {
    try {
      const { data } = await axios.get(
         `https://api.multiversx.com/transactions?sender=${address}&receiver=${missionAddress}&function=endMission&order=desc&withOperations=true&status=success`
      );
      console.log(data)
      return data;
    } catch (err) {
      return [];
    }
  }
}

export default AccountService;
