import { sftsRewards } from "config";
import { fetchSftsStaked } from "./getUserSFTsStaked"

export const getDailyProduction = async (address: string): Promise<any[]> => {
    const sfts = await fetchSftsStaked(address);
    const rewards: any = {
        food: 0,
        beer: 0,
        wood: 0,
        stone: 0,
        iron: 0,
        wargear: 0,
    };
    for(const sft of sfts) {
        const sftReward = sftsRewards.find((sftReward) => sftReward.nonce === Number(sft.nonce));
        if(!sftReward) continue;
        const resources = sftReward.resources;

        for(const resource of resources) {
            rewards[resource.type] += resource.amount * sft.balance
        }
    }
    return Object.entries(rewards).map(([name, amount]) => ({ name, amount }))
}