const nonceToImageMap: { [key: string]: string } =  {
    "1": "/assets/img/collection/1.png",
    "2": "/assets/img/collection/2.png",
    "3": "/assets/img/collection/3.png",
    "4": "/assets/img/collection/4.png",
    "5": "/assets/img/collection/5.png",
    "6": "/assets/img/collection/6.png",
    "7": "/assets/img/collection/7.png",
    "8": "/assets/img/collection/8.png",
    "9": "/assets/img/collection/9.png",
    "10": "/assets/img/collection/10.png",
    "11": "/assets/img/collection/11.png",
    "12": "/assets/img/collection/12.png",
    "13": "/assets/img/collection/13.png",
    "14": "/assets/img/collection/14.png",
    "15": "/assets/img/collection/15.png",
    "16": "/assets/img/collection/16.png",
    "17": "/assets/img/collection/17.png",
    "18": "/assets/img/collection/18.png",
    "19": "/assets/img/collection/19.png",
    "20": "/assets/img/collection/20.png",
    "21": "/assets/img/collection/21.png",
    "22": "/assets/img/collection/22.png",
    "23": "/assets/img/collection/23.png",
    "24": "/assets/img/collection/24.png",
    "25": "/assets/img/collection/25.png",
    "26": "/assets/img/collection/26.png",
    "27": "/assets/img/collection/27.png",
    "28": "/assets/img/collection/28.png",
    "29": "/assets/img/collection/29.png",
    "30": "/assets/img/collection/30.png",
    "31": "/assets/img/collection/31.png",
    "32": "/assets/img/collection/32.png",
    "33": "/assets/img/collection/33.png",
    "34": "/assets/img/collection/34.png",
    "35": "/assets/img/collection/35.png",
    "36": "/assets/img/collection/36.png",
    "37": "/assets/img/collection/37.png",
    "38": "/assets/img/collection/38.png",
    "39": "/assets/img/collection/39.png",
    "40": "/assets/img/collection/40.png",
    "41": "/assets/img/collection/41.png",
    "42": "/assets/img/collection/42.png",
    "43": "/assets/img/collection/43.png",
    "44": "/assets/img/collection/44.png",
    "45": "/assets/img/collection/45.png",
    "46": "/assets/img/collection/46.png",
    "47": "/assets/img/collection/47.png",
    "48": "/assets/img/collection/48.png",
    "49": "/assets/img/collection/49.png",
    "50": "/assets/img/collection/50.png",
    "51": "/assets/img/collection/51.png",
    "52": "/assets/img/collection/52.png",
    "53": "/assets/img/collection/53.png",
    "54": "/assets/img/collection/54.png",
    "55": "/assets/img/collection/55.png",
    "56": "/assets/img/collection/56.png",
    "57": "/assets/img/collection/57.png",
    "58": "/assets/img/collection/58.png",
    "59": "/assets/img/collection/59.png",
    "60": "/assets/img/collection/60.png",
    "61": "/assets/img/collection/61.png",
    "62": "/assets/img/collection/62.png",
    "63": "/assets/img/collection/63.png",

    // ... alte mapări
  };
  
  export default nonceToImageMap;
  export {};