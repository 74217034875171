export enum ProductionType {
    FOOD = "FOOD",
    BEER = "BEER",
    WOOD = "WOOD",
    IRON = "IRON",
    STONE = "STONE",
    WARGEAR = "WARGEAR",
    GOLD = "GOLD",
    SPECIAL = "SPECIAL",
    NONE = "NONE",
}  