import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import SFTResourceList from './SFTResourceList';
import { NftTypeWithQuantity } from 'utils/types';
import account from 'store/AccountStore';

export const DisplaySft = observer(
  ({ sft, onSelect, exists }:
    {
      sft: NftTypeWithQuantity;
      onSelect: (sft: NftTypeWithQuantity) => void;
      exists: (sft: NftTypeWithQuantity) => boolean;
    }) => {

    return (
      <Card
        className={`${
          exists(sft)
            ? 'sft-selected'
            : undefined
        }`}
      >
        <div className='image-container' style={{
          background: '#fbf2eb',
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px'
        }}>
          <Card.Img
            src={sft.url}
            style={{ borderRadius: '10px 10px 0 0' }}
            onClick={() => onSelect(sft)}
          />
        </div>
        <Card.Header className='d-flex justify-content-between'>
          <span>{sft.name}</span>
          <span>x{sft.balance}</span>
        </Card.Header>
        {exists(sft) &&
          (
            <div className='d-flex justify-content-between'>
              <div>
                <button
                  className='btn custom-btn border-fit-1'
                  onClick={() => {account.setMinSftQuantity(sft)}}
                >
                  MIN
                </button>
                <button
                  className='btn custom-btn-raid-left'
                  onClick={() => {account.decreaseSftQuantity(sft)}}
                >
                  -
                </button>
              </div>
              
              <h4>{sft.quantity}</h4>
              <div>
                <button
                  className='btn custom-btn-raid-right'
                  onClick={() => {account.increaseSftQuantity(sft)}}
                >
                  +
                </button>
                <button
                  className='btn custom-btn border-fit-2'
                  onClick={() => {account.setMaxSftQuantity(sft)}}
                >
                  MAX
                </button>
              </div>
            </div>
          )}
      </Card>
    );
  }
);
