import axios from 'axios';
import { MICROSERVICE_URL } from 'config';
import React, { useEffect, useState } from 'react'
import { IMission } from 'utils/types';

const useMission = (): { missions: IMission[], loading: boolean } => {
    const [loading, setLoading] = useState<boolean>(true);
    const [missions, setMissions] = useState<IMission[]>([]);

    const fetchMissions = async () => {
        try {
            const { data } = await axios.get(MICROSERVICE_URL + '/missions');
            const current_hour = new Date().getHours();
            const id = current_hour % 6 + 1;
            setMissions(data.filter((mission: IMission) => mission.id === id || mission.id === id + 6 || mission.id === id + 12 || mission.id === id + 18 || mission.id === id + 24));
        } catch (err) {
            console.log(err)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchMissions()
    }, [])

  return {
    missions,
    loading
  }
}

export default useMission