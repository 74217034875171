import axios from 'axios';
import { MICROSERVICE_URL } from '../../../config';
import { LeaderboardUser } from 'utils/types';

export const fetchLeaderboard = async (): Promise<LeaderboardUser[]> => {
  try {
    const { data } = await axios.get(`${MICROSERVICE_URL}/leaderboard`);

    return data;
  } catch (error) {
    return [];
  }
};