import React, { FC, SetStateAction, useEffect } from 'react'
import { useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'
import { Modal } from 'react-bootstrap'
import { Dispatch } from 'react';
import { Loading } from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { API_URL, chestsAddress, sftDetails } from 'config';
import { decodeBase64 } from '@multiversx/sdk-dapp/utils';
import { PrizeI, getWinPrize } from '../hooks/getWinPrize';
import { base64ToHex } from 'muf-utils';
import BigNumber from 'bignumber.js';
import { hexToNumber } from 'ts-apc-utils';

const CardResult: FC<Props> = observer(({ sessionId, setSessionId }) => {
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId
    })

  return (
    <Modal
        show={transactionStatus.transactions && transactionStatus.transactions?.length > 0}
        onHide={() => {
            if(transactionStatus.status === 'success' || transactionStatus.status === 'fail') {
                setSessionId('')
            }
        }}
        backdrop
    >
        <Modal.Header>
            <Modal.Title>Transaction Status</Modal.Title>
            <FontAwesomeIcon size='2x' icon={faTimesCircle} onClick={() => setSessionId('')} cursor='pointer' />
        </Modal.Header>
        <Modal.Body>
            <CardResultStatus
                status={transactionStatus.status}
                hash={transactionStatus.transactions ? transactionStatus.transactions[0].hash : ''}
            />
        </Modal.Body>
    </Modal>
  )
})

const CardResultStatus: FC<PropsStatus> = observer(({ status, hash }) => {
    const { address } = useGetAccountInfo();
    const [show, setShow] = React.useState(false);
    const [prizes, setPrizes] = React.useState<PrizeI[]>();

    useEffect(() => {
        const getSCResult = async () => {
            try {
                const { data } = await axios.get(API_URL + '/transactions/' + hash)
                const result = data.results.filter((result: any) => result.sender === address && result.receiver === chestsAddress)[0];
                const events = result.logs.events.filter((event: any) => event.identifier === 'buy');
                const prizesFound = [];

                for(const event of events) {
                    const identifier = decodeBase64(event.topics[1]);
                    const nonce = event.topics[2] === "" ? 0 : hexToNumber(Buffer.from(event.topics[2], 'base64').toString('hex'));
                    const amount = new BigNumber(base64ToHex(event.topics[3]), 16).toString();
                    prizesFound.push({
                        name: nonce === 0 ? identifier : sftDetails.find((sft) => sft.nonce === nonce)!.name,
                        nonce: nonce,
                        amount: nonce === 0 ? new BigNumber(base64ToHex(event.topics[3]), 16).div(Math.pow(10, 18)).toString() : amount,
                    })
                }
                setPrizes(prizes)
            } catch (error) {
                console.log(error)
            }
        }

        if(status === 'success') {
            getSCResult()
        }
    }, [status])

    if(!prizes) {
        return (
            <>
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '150px', maxHeight: '200px' }}>
                    <Loading loadingText='Waiting for result...' />
                </div>
            </>
        )
    }

    if(status === 'fail') {
        return (
            <>
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '150px', maxHeight: '200px' }}>
                    <FontAwesomeIcon icon={faTimesCircle} color={'red'} fontSize={'80px'} />
                    <span>Transaction failed</span>
                </div>
            </>
        )
    }

        return (
            <>
                <div
                    className='d-flex flex-column justify-content-center align-items-center'
                    style={{
                        height: '100%',
                        position: 'relative'
                    }}
                >
                    <video width={'100%'} height={'100%'} autoPlay playsInline onEnded={() => setShow(true)}>
                        <source src="/chest.mp4" type="video/mp4" />
                    </video>
                    <div
                        style={{
                            position: 'absolute',
                            top: '62%',
                            transform: 'translateY(-38%)'
                        }}
                    >
                        {
                            show && (
                                <div>
                                    {
                                        prizes?.map((prize, index) => (
                                            <p key={index}>x{Number(prize.amount).toLocaleString('en-EN', { maximumFractionDigits: 2 })} {prize.name}</p>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
        )
})

interface Props {
    sessionId: string
    setSessionId: Dispatch<SetStateAction<string>>
}

interface PropsStatus {
    status: string,
    hash: string
}

export default CardResult