import React, { FC, useState } from 'react'
import { Loading } from 'components/Loading';
import { Col, Row } from 'react-bootstrap';
import { IMission } from 'utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHeart, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Beer, Bread, Iron, Shield, Sword } from 'assets/icons';
import CustomModal from 'components/CustomModal';
import account from 'store/AccountStore';
import { sftStore } from 'pages/Map/store/SftStore';
import { observer } from 'mobx-react-lite';
import { characters } from 'config';
import { goToMissionTransaction } from 'utils/transactions';
import BigNumber from 'bignumber.js';
import { getTokenIcon } from 'utils/functions/getTokenIcon';
import { DisplaySftsForRaid } from 'components/DisplaySftsForRaid';

export const MissionItem: FC<MissionItemProps> = observer(({ mission }) => {
    const [show, setShow] = useState<boolean>(false);
    const { sfts, activeMissions } = account;
    const minutes = mission.time / 60;

    const sftsFiltred = sfts.filter((sft) => characters.includes(sft.nonce))

    const handleOpen = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        sftStore.resetSft()
    }

    const handleTransaction = async (address: string, fighters: any[]) => {
        goToMissionTransaction({
            sender: address,
            fighters,
            mission_id: mission.id
        })
        setTimeout(() => {
            handleClose()
        }, 1000)
    }
    return (
        <>
            <Col xs={12} md={4} className='mt-3 mb-md-0'>
                <div className="mission-card" style={{ position: 'relative' }}>
                    <img
                        src={require(`../../../assets/img/missions/tier-${mission.tier.toLowerCase()}.webp`)}
                        alt="mission-1"
                        width={'100%'}
                        className='border-radius-20'
                    />
                    <div className="mission-card-item-id">
                        <h3>Tier {mission.tier}</h3>
                    </div>
                    <div className="mission-card-item-content text-white mb-3" style={{
                        background: 'rgba(0,0,0,0.5)',
                        padding: '5px 12px',
                        borderRadius: '12px'
                    }}>
                        <h5 className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faClock} /> &nbsp;
                            {minutes}min
                        </h5>
                        <div className="d-flex align-items-center" style={{ gap: '12px' }}>
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <h5>{(mission.hp / 1000).toLocaleString()}</h5> &nbsp;
                                <FontAwesomeIcon icon={faHeart} color='white' fontSize={20} />
                            </div>
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <h5>{(mission.attack / 1000).toLocaleString()}</h5>
                                <Sword width={22} height={22} fill='white' />
                            </div>
                        </div>
                        <button
                            className='mission-card-item-content-btn mb-2'
                            onClick={handleOpen}
                            disabled={activeMissions.find((item) => item.id === mission.id) !== undefined}
                        >
                            Select
                        </button>
                        <div className="d-flex" style={{ gap: 5 }}>
                            {
                                mission.prize_pool.map((prize, index) => (
                                    <div key={index} className='d-flex align-items-center' style={{ gap: 4, flexDirection: 'column' }}>
                                        <span>{new BigNumber(prize.amount).div(Math.pow(10, 18)).toString()}</span>
                                        {
                                            prize.token_identifier.split('-')[0] === 'IRON' ? getTokenIcon(prize.token_identifier.split('-')[0].toUpperCase(), '30px', undefined, { className: 'mission-card-item-content-token'} ) : getTokenIcon(prize.token_identifier.split('-')[0].toUpperCase(), '20px', undefined, { className: 'mission-card-item-content-token'} )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Col>
            <CustomModal show={show} onHide={handleClose} title={'Chose your character'}>
                <DisplaySftsForRaid
                    sfts={sftsFiltred}
                    func={handleTransaction}
                    onCloseModal={handleClose}
                    name="mission"
                />
            </CustomModal>
        </>
    )
})

interface MissionItemProps {
    mission: IMission,
}

export const ListOfMissions: FC<Props> = observer(({ loading, missions }) => {
    if(loading) return <Loading />;
    return (
        <>
            <Row>
                <Col className='mission-card pb-4 pt-2 px-4 no-border'>
                    <Row style={{ justifyContent: 'center' }}>
                        {missions.map((mission) => (
                            <MissionItem mission={mission} key={mission.id} />
                        ))}
                    </Row>
                    <Row className='mt-4 px-4'>
                        <Col className='mission-card-info py-3 px-4'>
                            <div className="mission-card-info-content flex-column flex-md-row mx-3">
                                <FontAwesomeIcon icon={faInfo} color="#703104" fontSize={50} className='mr-md-4 mb-3 mb-md-0' />
                                <p>
                                    Class D:  no defense, 3-minute timer, up to 10 resources reward, and 1 leaderboard point.<br/>
                                    Class C: low defense, 5-minute timer, 50-100 resources reward, and 2 leaderboard points.<br/>
                                    Class B: mid-defense, 10-minute timer, 100-300 resources reward, and 3 leaderboard points.<br/>
                                    Class A: high defense, 30-minute timer, up to 300-500 resources reward, and 5 leaderboard points.<br/>
                                    Class S: super high defense, 60-minute timer, up to 500-2000 resources reward, and 10 leaderboard points.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
})

interface Props {
    missions: IMission[],
    loading: boolean
}
