export const swapAddress =
  'erd1qqqqqqqqqqqqqpgqzfu6mh4ryxmv2x7va4cuc437nh3qaq7lwmfs2fctds';
export const stakeAddress =
  'erd1qqqqqqqqqqqqqpgqdjfrnwzygxl06n2v0js6ar0vjwgmcjnswmfsays9c6';
export const marketplaceAddress =
  'erd1qqqqqqqqqqqqqpgqrcevydh86avvklugsg9mpdhd2yqh4mstwmfsjhv3jk';
export const craftAddress =
  'erd1qqqqqqqqqqqqqpgqrgdzm6w7p5g6mtc59dkgy8u38adng2h7tusqmdm3sj';
export const missionAddress =
  'erd1qqqqqqqqqqqqqpgqt0404a43znlzqegt9defqv0kljsr7yj7wmfsep5tmj';
export const chestsAddress = 'erd1qqqqqqqqqqqqqpgq8a4d8t9nts7feplsrutgkz80rvm0m3m8wmfsk29k74';
export const raidAddress = 'erd1qqqqqqqqqqqqqpgqau6phx8tzxe370juj4q2pczpns6sxzh5wmfsg3vk4s';
export const dAppName = 'xCastle';
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';
export const apiTimeout = 6000;
export const transactionSize = 15;
export const MICROSERVICE_URL = 'https://localhost:3141';
export const API_URL = 'https://mvx-api.estar.games';
// export const API_URL = 'https://api.multiversx.com';
export const EXPLORER_URL = 'https://explorer.multiversx.com';
export const AUCTION_API = 'http://localhost:3006';
export const AUCTION_OWNER_ADDRESS = 'erd1qqqqqqqqqqqqqpgqf0vvjhf6wrzvt0ytqydn70l3m7fcx0rawmfsp6zhfx'
export const AUCTION_SMART_CONTRACT_ADDRESS = 'erd1qqqqqqqqqqqqqpgqrpjzjmxcz2p97zamz99qtc2a0j5zncavwmfsfywhv0'
export const TOKEN_IDENTIFIER = 'ESTAR-461bab';
export const TOKEN_DECIMALS = 1000000000000000000;
export const FOOD_IDENTIFIER = 'FOOD-46261d';
export const FOOD_DECIMALS = 1000000000000000000;
export const WOOD_IDENTIFIER = 'WOOD-2f70ee';
export const WOOD_DECIMALS = 1000000000000000000;
export const IRON_IDENTIFIER = 'IRON-abe3cd';
export const IRON_DECIMALS = 1000000000000000000;
export const STONE_IDENTIFIER = 'STONE-6830a4';
export const STONE_DECIMALS = 1000000000000000000;
export const BEER_IDENTIFIER = 'BEER-093bf2';
export const BEER_DECIMALS = 1000000000000000000;
export const WARGEAR_IDENTIFIER = 'WARGEAR-932f1d';
export const WARGEAR_DECIMALS = 1000000000000000000;
export const COLLECTION_IDENTIFIER = 'XCASTLE-ee733b';

export const missionsTime = [
  {
    id: 1,
    time: 180
  },
  {
    id: 2,
    time: 180
  },
  {
    id: 3,
    time: 180
  },
  {
    id: 4,
    time: 180
  },
  {
    id: 5,
    time: 180
  },
  {
    id: 6,
    time: 180
  },
  {
    id: 7,
    time: 300
  },
  {
    id: 8,
    time: 300
  },
  {
    id: 9,
    time: 300
  },
  {
    id: 10,
    time: 300
  },
  {
    id: 11,
    time: 300
  },
  {
    id: 12,
    time: 300
  },
  {
    id: 13,
    time: 600
  },
  {
    id: 14,
    time: 600
  },
  {
    id: 15,
    time: 600
  },
  {
    id: 16,
    time: 600
  },
  {
    id: 17,
    time: 600
  },
  {
    id: 18,
    time: 600
  },
  {
    id: 19,
    time: 1800
  },
  {
    id: 20,
    time: 1800
  },
  {
    id: 21,
    time: 1800
  },
  {
    id: 22,
    time: 1800
  },
  {
    id: 23,
    time: 1800
  },
  {
    id: 24,
    time: 1800
  },
  {
    id: 25,
    time: 3600
  },
  {
    id: 26,
    time: 3600
  },
  {
    id: 27,
    time: 3600
  },
  {
    id: 28,
    time: 3600
  },
  {
    id: 29,
    time: 3600
  },
  {
    id: 30,
    time: 3600
  },
]

export interface SFTReward {
  nonce: number;
  resources: any[]
}

export const raidCharacters = [
  8, 13, 59, 61, 62, 63
]

export const characters = [
  8, 11, 10, 13, 12, 48, 59, 60, 61, 62, 63
]

export const charactersName = [
  {
    nonce: 8,
    name: 'Common Archer'
  },
  {
    nonce: 11,
    name: 'Common Innkeeper'
  },
  {
    nonce: 10,
    name: 'Common Farmer'
  },
  {
    nonce: 13,
    name: 'Common Soldier'
  },
  {
    nonce: 12,
    name: 'Common Smith'
  },
  {
    nonce: 48,
    name: 'Rare Bard'
  },
  {
    nonce: 59,
    name: 'Common Hunter'
  },
  {
    nonce: 60,
    name: 'Common Prospector'
  },
  {
    nonce: 61,
    name: 'Rare Hunter'
  },
  {
    nonce: 62,
    name: 'Epic Hunter'
  },
  {
    nonce: 63,
    name: 'Legendary Hunter'
  }
]

export const sftDetails = [
  {
    nonce: 1,
    name: 'Key',
    description: 'You can open a chest with this key, check the chest rewards system for more information',
    type: 'Item',
    productionBonus: '',
    attack: '',
    defense: '',
    carrying: '',
    multiplierAttack: '',
    multiplierDefense: '',
    element: ''
  },
  {
    nonce: 8,
    name: 'Common Archer',
    description: '',
    type: 'Army',
    productionBonus: '',
    attack: '13',
    defense: '7',
    carrying: '1',
    multiplierAttack: '',
    multiplierDefense: '',
    element: ''
  },
  {
    nonce: 9,
    name: 'Common Banker',
    description: '',
    type: 'Worker',
    productionBonus: 'Gold',
    attack: 'none',
    defense: 'none',
    carrying: '50',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 10,
    name: 'Common Farmer',
    description: '',
    type: 'Worker',
    productionBonus: 'Food',
    attack: 'none',
    defense: 'none',
    carrying: '10',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 11,
    name: 'Common Innkeeper',
    description: '',
    type: 'Worker',
    productionBonus: 'Beer',
    attack: 'none',
    defense: 'none',
    carrying: '10',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 12,
    name: 'Common Smith',
    description: '',
    type: 'Worker',
    productionBonus: 'Iron',
    attack: 'none',
    defense: 'none',
    carrying: '10',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 13,
    name: 'Common Soldier',
    description: '',
    type: 'Army',
    productionBonus: 'none',
    attack: '11',
    defense: '10',
    carrying: '1',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'

  },
  {
    nonce: 48,
    name: 'Rare Bard',
    description: '',
    type: 'Worker',
    productionBonus: 'Beer',
    attack: 'none',
    defense: 'none',
    carrying: '30',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 59,
    name: 'Common Hunter',
    description: '',
    type: 'Army',
    productionBonus: 'none',
    attack: '2',
    defense: '1',
    carrying: '1',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 61,
    name: 'Rare Hunter',
    description: '',
    type: 'Army',
    productionBonus: 'none',
    attack: '9',
    defense: '5',
    carrying: '5',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 62,
    name: 'Epic Hunter',
    description: '',
    type: 'Army',
    productionBonus: 'none',
    attack: '38',
    defense: '25',
    carrying: '10',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 63,
    name: 'Legendary Hunter',
    description: '',
    type: 'Army',
    productionBonus: 'none',
    attack: '160',
    defense: '125',
    carrying: '20',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 60,
    name: 'Common Prospector',
    description: '',
    type: 'Worker',
    productionBonus: 'Stone',
    attack: 'none',
    defense: 'none',
    carrying: '30',
    multiplierAttack: 'none',
    multiplierDefense: 'none',
    element: 'none'
  },
  {
    nonce: 2,
    name: 'Common Mill',
    description: '',
    type: 'Building',
    foodProduction: '3',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '8',
  },
  {
    nonce: 3,
    name: 'Common Forge',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '3',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '8',
  },
  {
    nonce: 4,
    name: 'Common Watch Tower',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '3',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '8',
  },
  {
    nonce: 5,
    name: 'Common Bank',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '5',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '8',
  },
  {
    nonce: 6,
    name: 'Common Tavern',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '3',
    woodProduction: '',
    stoneProduction: '',
    score: '8',
  },
  {
    nonce: 7,
    name: 'Common House',
    description: '',
    type: 'Building',
    foodProduction: '3',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '8',
  },
  {
    nonce: 14,
    name: 'Rare House',
    description: '',
    type: 'Building',
    foodProduction: '12',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '20',
  },
  {
    nonce: 15,
    name: 'Rare Bank',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '15',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '20',
  },
  {
    nonce: 16,
    name: 'Rare Forge',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '12',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '20',
  },
  {
    nonce: 17,
    name: 'Rare Tavern',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '12',
    woodProduction: '',
    stoneProduction: '',
    score: '20',
  },
  {
    nonce: 18,
    name: 'Rare Watch Tower',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '12',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '20',
  },
  {
    nonce: 19,
    name: 'Rare Mill',
    description: '',
    type: 'Building',
    foodProduction: '12',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '20',
  },
  {
    nonce: 20,
    name: 'Epic House',
    description: '',
    type: 'Building',
    foodProduction: '48',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '60',
  },
  {
    nonce: 21,
    name: 'Epic Bank',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '60',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '60',
  },
  {
    nonce: 22,
    name: 'Epic Forge',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '48',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '60',
  },
  {
    nonce: 23,
    name: 'Epic Mill',
    description: '',
    type: 'Building',
    foodProduction: '48',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '0',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '60',
  },
  {
    nonce: 24,
    name: 'Epic Tavern',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '48',
    woodProduction: '',
    stoneProduction: '',
    score: '60',
  },
  {
    nonce: 25,
    name: 'Epic Watch Tower',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '48',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '60',
  },
  {
    nonce: 26,
    name: 'Legendary Bank',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '240',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '250',
  },
  {
    nonce: 27,
    name: 'Legendary Forge',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '192',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '250',
  },
  {
    nonce: 28,
    name: 'Legendary House',
    description: '',
    type: 'Building',
    foodProduction: '192',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '250',
  },
  {
    nonce: 29,
    name: 'Legendary Mill',
    description: '',
    type: 'Building',
    foodProduction: '192',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '250',
  },
  {
    nonce: 30,
    name: 'Legendary Tavern',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '192',
    woodProduction: '',
    stoneProduction: '',
    score: '250',
  },
  {
    nonce: 27,
    name: 'Legendary Watch Tower',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '192',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '250',
  },
  {
    nonce: 33,
    name: 'Common Blacksmith',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '3',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '7',
  },
  {
    nonce: 34,
    name: 'Common Brewery',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '3',
    woodProduction: '',
    stoneProduction: '',
    score: '7',
  },
  {
    nonce: 35,
    name: 'Common Farm',
    description: '',
    type: 'Building',
    foodProduction: '3',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '7',
  },
  {
    nonce: 36,
    name: 'Common Iron Mine',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '3',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '7',
  },
  {
    nonce: 37,
    name: 'Common Lumber Yard',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '3',
    stoneProduction: '',
    score: '7',
  },
  {
    nonce: 38,
    name: 'Common Quarry',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '3',
    score: '7',
  },
  {
    nonce: 39,
    name: 'Rare Blacksmith',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '12',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '15',
  },
  {
    nonce: 340,
    name: 'Rare Brewery',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '12',
    woodProduction: '',
    stoneProduction: '',
    score: '15',
  },
  {
    nonce: 41,
    name: 'Rare Farm',
    description: '',
    type: 'Building',
    foodProduction: '12',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '15',
  },
  {
    nonce: 42,
    name: 'Rare Iron Mine',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '12',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '15',
  },
  {
    nonce: 43,
    name: 'Common Lumber Yard',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '12',
    stoneProduction: '',
    score: '15',
  },
  {
    nonce: 44,
    name: 'Rare Quarry',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '12',
    score: '15',
  },
  {
    nonce: 46,
    name: 'Epic Blacksmith',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '48',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '50',
  },
  {
    nonce: 47,
    name: 'Epic Brewery',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '48',
    woodProduction: '',
    stoneProduction: '',
    score: '50',
  },
  {
    nonce: 49,
    name: 'Epic Farm',
    description: '',
    type: 'Building',
    foodProduction: '48',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '50',
  },
  {
    nonce: 50,
    name: 'Epic Iron Mine',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '48',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '50',
  },
  {
    nonce: 51,
    name: 'Epic Lumber Yard',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '48',
    stoneProduction: '',
    score: '50',
  },
  {
    nonce: 52,
    name: 'Epic Quarry',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '48',
    score: '50',
  },
  {
    nonce: 53,
    name: 'Legendary Blacksmith',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '192',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '200',
  },
  {
    nonce: 54,
    name: 'Legendary Brewery',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '192',
    woodProduction: '',
    stoneProduction: '',
    score: '200',
  },
  {
    nonce: 55,
    name: 'Legendary Farm',
    description: '',
    type: 'Building',
    foodProduction: '192',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '200',
  },
  {
    nonce: 56,
    name: 'Legendary Iron Mine',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '192',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '',
    score: '200',
  },
  {
    nonce: 57,
    name: 'Legendary Lumber Yard',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '192',
    stoneProduction: '',
    score: '200',
  },
  {
    nonce: 58,
    name: 'Legendary Quarry',
    description: '',
    type: 'Building',
    foodProduction: '',
    ironProduction: '',
    wargearProduction: '',
    eccuProduction: '',
    beerProduction: '',
    woodProduction: '',
    stoneProduction: '192',
    score: '200',
  }
]

export const sftsRewards: SFTReward[] = [
  {
    nonce: 2,
    resources: [{ type: 'food', amount: 3 }]
  },
  {
    nonce: 3,
    resources: [{ type: 'iron', amount: 3 }]
  },
  {
    nonce: 4,
    resources: [{ type: 'wargear', amount: 3 }]
  },
  {
    nonce: 5,
    resources: [{ type: 'wargear', amount: 5 }]
  },
  {
    nonce: 6,
    resources: [{ type: 'beer', amount: 3 }]
  },
  {
    nonce: 7,
    resources: [{ type: 'food', amount: 3 }]
  },
  {
    nonce: 14,
    resources: [{ type: 'food', amount: 12 }]
  },
  {
    nonce: 15,
    resources: [{ type: 'wargear', amount: 15 }]
  },
  {
    nonce: 16,
    resources: [{ type: 'iron', amount: 12 }]
  },
  {
    nonce: 17,
    resources: [{ type: 'beer', amount: 12 }]
  },
  {
    nonce: 18,
    resources: [{ type: 'wargear', amount: 12 }]
  },
  {
    nonce: 19,
    resources: [{ type: 'food', amount: 12 }]
  },
  {
    nonce: 20,
    resources: [{ type: 'food', amount: 48 }]
  },
  {
    nonce: 21,
    resources: [{ type: 'wargear', amount: 60 }]
  },
  {
    nonce: 22,
    resources: [{ type: 'iron', amount: 48 }]
  },
  {
    nonce: 23,
    resources: [{ type: 'food', amount: 48 }]
  },
  {
    nonce: 24,
    resources: [{ type: 'beer', amount: 48 }]
  },
  {
    nonce: 25,
    resources: [{ type: 'wargear', amount: 48 }]
  },
  {
    nonce: 26,
    resources: [{ type: 'wargear', amount: 240 }]
  },
  {
    nonce: 27,
    resources: [{ type: 'iron', amount: 192 }]
  },
  {
    nonce: 28,
    resources: [{ type: 'food', amount: 192 }]
  },
  {
    nonce: 29,
    resources: [{ type: 'food', amount: 192 }]
  },
  {
    nonce: 30,
    resources: [{ type: 'beer', amount: 192 }]
  },
  {
    nonce: 31,
    resources: [{ type: 'wargear', amount: 192 }]
  },
  {
    nonce: 33,
    resources: [{ type: 'wargear', amount: 3 }]
  },
  {
    nonce: 34,
    resources: [{ type: 'beer', amount: 3 }]
  },
  {
    nonce: 35,
    resources: [{ type: 'food', amount: 3 }]
  },
  {
    nonce: 36,
    resources: [{ type: 'iron', amount: 3 }]
  },
  {
    nonce: 37,
    resources: [{ type: 'wood', amount: 3 }]
  },
  {
    nonce: 38,
    resources: [{ type: 'stone', amount: 3 }]
  },
  {
    nonce: 39,
    resources: [{ type: 'wargear', amount: 12 }]
  },
  {
    nonce: 40,
    resources: [{ type: 'beer', amount: 12 }]
  },
  {
    nonce: 41,
    resources: [{ type: 'food', amount: 12 }]
  },
  {
    nonce: 42,
    resources: [{ type: 'iron', amount: 12 }]
  },
  {
    nonce: 43,
    resources: [{ type: 'wood', amount: 12 }]
  },
  {
    nonce: 44,
    resources: [{ type: 'stone', amount: 12 }]
  },
  {
    nonce: 46,
    resources: [{ type: 'wargear', amount: 48 }]
  },
  {
    nonce: 47,
    resources: [{ type: 'beer', amount: 48 }]
  },
  {
    nonce: 49,
    resources: [{ type: 'food', amount: 48 }]
  },
  {
    nonce: 50,
    resources: [{ type: 'iron', amount: 48 }]
  },
  {
    nonce: 51,
    resources: [{ type: 'wood', amount: 48 }]
  },
  {
    nonce: 52,
    resources: [{ type: 'stone', amount: 48 }]
  },
  {
    nonce: 53,
    resources: [{ type: 'wargear', amount: 192 }]
  },
  {
    nonce: 54,
    resources: [{ type: 'beer', amount: 192 }]
  },
  {
    nonce: 55,
    resources: [{ type: 'food', amount: 192 }]
  },
  {
    nonce: 56,
    resources: [{ type: 'iron', amount: 192 }]
  },
  {
    nonce: 57,
    resources: [{ type: 'wood', amount: 192 }]
  },
  {
    nonce: 58,
    resources: [{ type: 'stone', amount: 192 }]
  },
];
