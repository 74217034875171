import { RouteType } from '@multiversx/sdk-dapp/types';
import { dAppName } from 'config';
import { withPageTitle } from './components/PageTitle';

import {
  Leaderboard,
  Home,
  MapPage,
  MyWallet,
  Shop,
  Chests,
  Profile,
  Raid,
  Story,
  Mission,
  Craft,
  Auction,
  Island,
} from './pages';


export const routeNames = {
  home: '/',
  map: '/dashboard/map',
  bazaar: '/dashboard/bazaar/shop',
  craft: '/dashboard/bazaar/craft',
  auction: '/dashboard/bazaar/auction',
  leaderBoard: '/dashboard/leaderboard',
  myWallet: '/dashboard/wallet',
  chests: '/dashboard/chests',
  lore: '/dashboard/lore',
  story: '/dashboard/lore/story',
  factionChurch: '/dashboard/lore/faction/church',
  factionMerchants: '/dashboard/lore/faction/merchants',
  factionWitches: '/dashboard/lore/faction/witches',
  factionCrown: '/dashboard/lore/faction/crown',
  missions: '/dashboard/missions',
  raid: '/dashboard/raid',
  profile: '/dashboard/profile/:address',
  islands: '/dashboard/island',
  island: '/dashboard/island/:id',
  unlock: '/unlock'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.map,
    title: 'Castle Map',
    component: MapPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.bazaar,
    title: 'Bazaar',
    component: Shop,
    authenticatedRoute: true
  },
  {
    path: routeNames.craft,
    title: 'Craft',
    component: Craft,
    authenticatedRoute: true
  },
  {
    path: routeNames.auction,
    title: 'Auction',
    component: Auction,
    authenticatedRoute: true
  },
  {
    path: routeNames.leaderBoard,
    title: 'Leaderboard',
    component: Leaderboard,
    authenticatedRoute: true
  },
  {
    path: routeNames.myWallet,
    title: 'My Wallet',
    component: MyWallet,
    authenticatedRoute: true
  },
  {
    path: routeNames.chests,
    title: 'Chests',
    component: Chests,
    authenticatedRoute: true
  },
  {
    path: routeNames.raid,
    title: 'Raid',
    component: Raid,
    authenticatedRoute: true
  },
  {
    path: routeNames.profile,
    title: 'Profile',
    component: Profile,
    authenticatedRoute: true
  },
  {
    path: routeNames.islands,
    title: 'Islands',
    component: Island,
    authenticatedRoute: true
  },
  {
    path: routeNames.island,
    title: 'Island',
    component: Island,
    authenticatedRoute: true
  },
  {
    path: routeNames.story,
    title: 'Story',
    component: Story,
    authenticatedRoute: true
  },
  {
    path: routeNames.missions,
    title: 'Missions',
    component: Mission,
    authenticatedRoute: true
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • MultiversX ${dAppName}`
    : `MultiversX ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
