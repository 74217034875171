import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { Map, Tile, TileType } from 'utils/types'
import { isValidTile } from '../hooks/isValidTile'
import { imagePaths } from '../data'

interface Props {
    canvasRef: RefObject<HTMLCanvasElement>
    map?: Map
    userOutposts: Tile[]
    offset: { x: number, y: number }
    scale: number
    showAvailable: boolean
    showCoord: boolean
    setOffset: ({x, y}: { x: number, y: number }) => void
    setScale: (value: number) => void
    setTile: (value: Tile) => void
}

const mapWidth = 101;
const mapHeight = 101;
const tileSize = 10;

const Canvas: FC<Props> = ({ canvasRef, map, userOutposts, offset, scale, showAvailable, showCoord, setOffset, setScale, setTile }) => {
    const [isDragging, setIsDragging] = useState(false);
    const dragStart = useRef({ x: 0, y: 0 });
    const images = useRef<Record<string, HTMLImageElement>>({});

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
    
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
    
        const preloadImages = async () => {
          const loadImage = (src: string): Promise<HTMLImageElement> => {
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.onload = () => resolve(image);
              image.onerror = (error) => reject(error);
              image.src = src;
            });
          };
    
          const imagePromises = Object.entries(imagePaths).map(([key, value]) => {
            return loadImage(value).then((image) => {
              images.current[key] = image;
            });
          });
    
          Promise.all(imagePromises).then(() => {
                drawMap()
            });
        };
    
        preloadImages();
    
        const handleWheel = (event: WheelEvent) => {
          event.preventDefault();
          if(!canvasRef.current) return
          const canvasRect = canvasRef.current.getBoundingClientRect();
          const mouseX = event.clientX - canvasRect.left;
          const mouseY = event.clientY - canvasRect.top;
        
          const worldX = (mouseX - offset.x) / scale;
          const worldY = (mouseY - offset.y) / scale;
        
          const targetTileX = Math.floor(worldX / tileSize);
          const targetTileY = Math.floor(worldY / tileSize);
        
          const wheelDirection = event.deltaY < 0 ? 1.1 : 0.9;
          let newScale = scale * wheelDirection;
        
          newScale = Math.max(newScale, 4);
          newScale = Math.min(newScale, 10);
        
          const targetCanvasX = targetTileX * tileSize * newScale + tileSize * newScale / 2;
          const targetCanvasY = targetTileY * tileSize * newScale + tileSize * newScale / 2;
        
          const newOffsetX = mouseX - targetCanvasX;
          const newOffsetY = mouseY - targetCanvasY;
        
          if (newScale !== scale) {
            setScale(newScale);
            updateOffset(newOffsetX, newOffsetY);
          }
        };
    
      const updateOffset = (newOffsetX: number, newOffsetY: number) => {
        if(!canvasRef.current) return
        const canvasWidth = canvasRef.current.offsetWidth;
        const canvasHeight = canvasRef.current.offsetHeight;
      
        const scaledMapWidth = mapWidth * tileSize * scale;
        const scaledMapHeight = mapHeight * tileSize * scale;
      
        const maxXOffset = Math.max(0, scaledMapWidth - canvasWidth);
        const maxYOffset = Math.max(0, scaledMapHeight - canvasHeight);
      
        const clampedOffsetX = Math.min(Math.max(newOffsetX, -maxXOffset), 0);
        const clampedOffsetY = Math.min(Math.max(newOffsetY, -maxYOffset), 0);
      
        setOffset({
          x: clampedOffsetX,
          y: clampedOffsetY,
        });
      };
    
        canvas.addEventListener('wheel', handleWheel, { passive: false });
    
        return () => {
          canvas.removeEventListener('wheel', handleWheel);
        };
      }, [scale, offset, map, showAvailable, showCoord]);
    
      const drawMap = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
    
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();
        ctx.translate(offset.x, offset.y);
        ctx.scale(scale, scale);
    
        map?.tiles.forEach(tile => {
          const x = tile.x * tileSize;
            const y = tile.y * tileSize;
            if(showAvailable && isValidTile(tile)) {
              ctx.fillStyle = 'red';
              ctx.fillRect(x, y, x, y)
            } else {
              const image = images.current[tile.type.toLowerCase()];
              if (image) {
                ctx.drawImage(image, x, y, tileSize, tileSize);

                if (tile.outpostLevel > 0) {
                  const outpost = images.current.outpost
                  if (outpost)
                    ctx.drawImage(outpost, x, y, tileSize, tileSize)
                }

                if(tile.type === TileType.CASTLE) {
                  ctx.strokeStyle = 'red';
                  ctx.lineWidth = 0.4;
                  ctx.strokeRect(x + 0.2, y + 0.2, tileSize - 0.4, tileSize - 0.4)
                }
              }
            }

            const isTileVisible = userOutposts.some(outpostTile => {
              const distanceFromOutpost = Math.abs(tile.x - outpostTile.x) + Math.abs(tile.y - outpostTile.y);
              return distanceFromOutpost <= 5;
            });
      
            if(!isTileVisible) {
              ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
              ctx.fillRect(x, y, tileSize, tileSize);
            }
  
            if(showCoord) {
              ctx.fillStyle = 'white';
              ctx.font = '3px Arial';
              ctx.fillText(`${tile.x};${tile.y}`, x + 1.2, y + 6);
            }
        });
        ctx.restore();
    };

    const handleMouseDown = (event: any) => {
        setIsDragging(true);
        dragStart.current = {
          x: event.clientX - offset.x,
          y: event.clientY - offset.y,
        };
    };
    
    const handleDoubleClick = (event: any) => {
        if(!canvasRef.current) return
        const canvasRect = canvasRef.current.getBoundingClientRect();
        const mouseX = event.clientX - canvasRect.left;
        const mouseY = event.clientY - canvasRect.top;
    
        const tileX = Math.floor((mouseX - offset.x) / (tileSize * scale));
        const tileY = Math.floor((mouseY - offset.y) / (tileSize * scale));
    
        const tile = map?.tiles.find((tile) => tile.x === tileX && tile.y === tileY);
        if(!tile) return
        setTile(tile)
    }
      
    const handleMouseMove = (event: any) => {
        if (isDragging) {
          if(!canvasRef.current) return
          let newOffsetX = event.clientX - dragStart.current.x;
          let newOffsetY = event.clientY - dragStart.current.y;
      
          const scaledMapWidth = mapWidth * tileSize * scale;
          const scaledMapHeight = mapHeight * tileSize * scale;
      
          const maxXOffset = canvasRef.current.offsetWidth - scaledMapWidth;
          const maxYOffset = canvasRef.current.offsetHeight - scaledMapHeight;
      
          newOffsetX = Math.min(Math.max(newOffsetX, maxXOffset), 0);
          newOffsetY = Math.min(Math.max(newOffsetY, maxYOffset), 0);
      
          setOffset({ x: newOffsetX, y: newOffsetY });
        }
    };
      
    const handleMouseUp = () => {
        setIsDragging(false);
    };

  return (
    <canvas
        ref={canvasRef}
        width={`${mapWidth * tileSize}px`}
        height={mapHeight * tileSize}
        style={{ touchAction: 'none', border: '4px solid black' }}
        onDoubleClick={handleDoubleClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        />
  )
}

export default Canvas