import { getFighters } from "./getFighters";

interface SFT {
    nonce: number
    balance: string
}

export const getUserStats = async (sfts: SFT[]): Promise<{ attack: number, m_attack: number, hp: number, m_hp: number }> => {
    const fighters = await getFighters();
    let attack = 0;
    let m_attack = 0;
    let hp = 0;
    let m_hp = 0;

    for(const sft of sfts) {
        for(const fighter of fighters) {
            if(fighter.nonce === sft.nonce) {
                attack += fighter.attack * Number(sft.balance);
                m_attack += fighter.m_attack * Number(sft.balance);
                hp += fighter.hp * Number(sft.balance);
                m_hp += fighter.m_hp * Number(sft.balance);
            }
        }
    }

    return {
        attack,
        m_attack,
        hp,
        m_hp,
    };
}