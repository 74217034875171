export const getTopRewards = (wallet: any) => {
    switch (wallet) {
      case 'erd1jkd354c4mz9cfp8vjh2cvv2vtc7fg4klfktrqp8c627r6x86sa8qquzu45':
        return "medal-gold";
      case 'erd1vmc36arznfsl03qnkzx2awx694w8nxuk9uhceytgmnewjdhk0cyqt5ma4f':
        return "medal-silver";
      case 'erd1fqwe3uhu8lrh63pszzu6ta84ylv06lns3zl54wkrn27ycst8phpqtugu5v':
        return "medal-bronze";
      default:
        return "";
    }
  };
  