import { getChainID, refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { COLLECTION_IDENTIFIER, missionAddress } from '../../config';
import { numHex } from '../functions/numHex';
import { Address, TokenTransfer, U64Value } from '@multiversx/sdk-core/out';
import { getSmartContract } from 'utils/functions/getSmartContract';
import { missionAbi } from 'utils/abi/mission';
import BigNumber from 'bignumber.js';

export const goToMissionTransaction = async (
    { sender, fighters, mission_id }: { sender: string, fighters: any[], mission_id: number }
    ) => {
      const contract = getSmartContract(missionAbi, new Address(missionAddress))
      if(fighters.length === 0) return;
      const transaction = contract.methods.goToMission([new U64Value(mission_id)]).withMultiESDTNFTTransfer(fighters.map((sft) => new TokenTransfer({
          tokenIdentifier: sft.collection,
          nonce: sft.nonce,
          amountAsBigInteger: new BigNumber(sft.quantity)
      })))
      .withSender(new Address(sender))
      .withChainID(getChainID())
      .withGasLimit(60_000_000)
      .buildTransaction()
      refreshAccount();
      sendTransactions({
          transactions: transaction
      });
}