import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Col, Row } from 'react-bootstrap'
import DisplaySftsStaked from './DisplaySftsStaked'
import DisplaySftsToStake from './DisplaySftsToStake'
import { Category, sftStore } from '../store/SftStore'
import { claimTransaction, stakeTransaction, unStakeTransaction } from 'utils/transactions'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import account from 'store/AccountStore'
import { Loading } from 'components/Loading'
import { getTokenIcon } from 'utils/functions/getTokenIcon'

const CastleMapModalContent: FC<Props> = observer(({ target }) => {
    const { loading, rewards } = account
    const { category, sftSelected, amount } = sftStore
    const { address } = useGetAccountInfo()
    const [disable, setDisable] = useState(true);

    const handleTransaction = () => {
        if(category === undefined) return
        if(sftSelected === undefined) return
        if(category === Category.stake) {
            stakeTransaction({
                amount,
                nonce: sftSelected?.nonce,
                reciver: address
            });
            sftStore.resetSft();
        }

        if(category === Category.unStake) {
            unStakeTransaction({ amount, nonce: sftSelected?.nonce });
            sftStore.resetSft();
        }
    }

    useEffect(() => {
        if(target === 'castle') account.loadRewards(address)
    }, [target])

    useEffect(() => {
        if(target === 'castle') {
            rewards.map((reward) => {
                if(reward.amount > 0) {
                    setDisable(false);
                    return;
                }
            })
        }
    }, [target, rewards])

    if(loading) return <Loading loadingText='Fetching account...' />

    if(target === 'castle') return (
        <>
            <Row className='p-3'>
                {
                    rewards.map((reward, id) => (
                        <Col xs={12} lg={1} key={id} style={{ marginRight: '20px' }}>
                            <div className='d-flex align-items-center justify-content-center'>
                                <div style={{ marginTop: '-10px'}}>
                                    {getTokenIcon(reward.name.toUpperCase())}
                                </div>
                                <h4
                                    className={'text-uppercase ml-2'}
                                >
                                    {reward.name}
                                </h4>
                            </div>
                            <p className='text-center'>{(reward.amount / Math.pow(10, 18)).toLocaleString('en-EN')}</p>
                        </Col>
                    ))
                }
            </Row>
            <Row>
                <Col className='d-flex justify-content-center'>
                    <button className='btn custom-btn' disabled={disable} onClick={() => claimTransaction()}>Claim</button>
                </Col>
            </Row>
        </>
    )
  return (
    <>
        <DisplaySftsStaked target={target} />
        <DisplaySftsToStake target={target} />
        <Button
            className='d-block mx-auto'
            onClick={handleTransaction}
            disabled={sftSelected === undefined}
        >
            {
                sftSelected === undefined ? 'Disabled' : category === Category.stake ? 'Stake' : 'Unstake'
            }
        </Button>
    </>
  )
})

interface Props {
    target: string
}

export default CastleMapModalContent