import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';

export const endMissionTransactionOld = async () => {
    const mission = {
        value: 0,
        data: `endMission`,
        receiver: 'erd1qqqqqqqqqqqqqpgq23f4ulzmrkw42hawjjgcncp3s9ysswftwmfscern64',
        gasLimit: 30000000
      };
      await refreshAccount();
    
      await sendTransactions({
        transactions: mission,
        transactionsDisplayInfo: {
          processingMessage: 'Processing transaction',
          errorMessage: 'An error has occured',
          successMessage: 'transaction successful'
        },
        redirectAfterSign: false
      });
}