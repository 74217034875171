import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import account from 'store/AccountStore'
import { getUserStats } from '../hooks/getUserStats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { Sword } from 'assets/icons';

const UserRaidStats = observer(() => {
  const [stats, setStats] = useState({
    attack: 0,
    m_attack: 0,
    hp: 0,
    m_hp: 0,
  });
  const { sfts } = account;
  useEffect(() => {
    getUserStats(sfts.map((sft) => ({ nonce: sft.nonce, balance: sft.balance }))).then((data) => setStats(data))
  }, [sfts])
  return (
    <div className="mission-card" style={{ position: 'relative' }}>
        <div className="mission-card-item-id">
            <h3>Your Stats</h3>
        </div>
        <img
            src={require(`../../../assets/img/raid-user-stats.jpeg`)}
            alt="raid"
            width={'100%'}
            className='border-radius-20'
        />
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '88%',
        }}>
          <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px',
              backgroundColor: "black",
              padding: '0 3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '20px',
              }}>
                DEFENSE:
              </span>
              <span className='text-white' style={{
                fontSize: '20px',
              }}>{stats.hp.toLocaleString()}</span>
              <FontAwesomeIcon icon={faHeart} color='white' fontSize={20} />
            </div>
            <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px',
              backgroundColor: "black",
              padding: '0 3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '20px',
              }}>
                DEFENSE MULTIPLIER:
              </span>
              <span className='text-white' style={{
                fontSize: '20px',
              }}>{stats.m_hp.toLocaleString()}</span>
              <FontAwesomeIcon icon={faHeart} color='red' fontSize={20} />
            </div>
            <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px',
              backgroundColor: "black",
              padding: '0 3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '20px',
              }}>
                ATTACK:
              </span>
              <span className='text-white' style={{
                fontSize: '20px',
              }}>{stats.attack.toLocaleString()}</span>
              <Sword width={22} height={22} fill='white' />
            </div>
            <div style={{
              display: "flex",
              alignItems: 'center',
              gap: '3px',
              backgroundColor: "black",
              padding: '0 3px'
              }}
            >
              <span className='text-white' style={{
                fontSize: '20px',
              }}>
                ATTACK MULTIPLIER:
              </span>
              <span className='text-white' style={{
                fontSize: '20px',
              }}>{stats.m_attack.toLocaleString()}</span>
              <Sword width={25} height={25} fill='red' />
            </div>
        </div>
    </div>
  )
})

export default UserRaidStats