import axios from "axios"
import { MICROSERVICE_URL } from "config"

export const deploy = async (id: string, x: number, y: number, address: string) => {
    const { data } = await axios.post(`${MICROSERVICE_URL}/islands/${id}/deploy`, {
        x,
        y,
        address
    })
    return data;
}