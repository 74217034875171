import { Col, Row } from 'react-bootstrap';
import { PageLayout } from '../../components/PageLayout';
import BossCard from './components/BossCard';
import UserRaidStats from './components/UserRaidStats';
import BestPlayer from './components/BestPlayer';
import { useEffect, useState } from 'react';
import { Boss, PlayerAttack } from 'utils/types';
import { getCurrentBoss } from './hooks/getCurrentBoss';
import BossAttackLog from './components/BossAttackLog';
import Bosses from './components/Bosses';
import { getPlayersAttack } from './hooks/getPlayersAttack';

export const Raid = () => {
  const [boss, setBoss] = useState<Boss>();
  const [attacks, setAttacks] = useState<PlayerAttack[]>([]);

  useEffect(() => {
    getCurrentBoss().then((data) => setBoss(data))
  }, [])

  useEffect(() => {
    if(!boss) return;
    getPlayersAttack(boss.id, Number(boss.prize_pool.amount) / Math.pow(10, 18)).then((data) => setAttacks(data))
  }, [boss])
  return (
    <PageLayout>
      <Row className='mb-3'>
        <Col>
          <h1 className='text-center'>Raid</h1>
        </Col>
      </Row>
      <Row className='mission-card p-4 no-border'>
        <Col md={4} xs={12}>
          <BossCard boss={boss} />
        </Col>
        <Col md={4} xs={12}>
          <UserRaidStats />
        </Col>
        <Col md={4} xs={12}>
          <BestPlayer attacks={attacks} />
        </Col>
      </Row>
      {boss && <Row>
        <Col xs={12}>
          <BossAttackLog attacks={attacks} />
        </Col>
      </Row>}
      <Row>
        <Col xs={12}>
          <Bosses boss_id={boss?.id} />
        </Col>
      </Row>
    </PageLayout>
  );
};
