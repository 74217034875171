import axios from "axios";
import { MICROSERVICE_URL } from "config";
import { Boss } from "utils/types";

export const getBosses = async (): Promise<Boss[]> => {
    try {
        const { data } = await axios.get(MICROSERVICE_URL + '/raid/bosses')
        return data.sort((a: Boss, b: Boss) => b.id - a.id)
    } catch (error) {
        console.log(error)
        return [];
    }
}