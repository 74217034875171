import React, { useEffect, useState } from 'react'
import { getUserTransactions } from '../hooks/getUserTransactions'
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks'
import { Col, Row, Table } from 'react-bootstrap';
import { EXPLORER_URL, chestsAddress, sftDetails } from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { decodeBase64 } from '@multiversx/sdk-dapp/utils';
import { hexToNumber } from 'ts-apc-utils';
import { getWinPrize } from '../hooks/getWinPrize';
import { getTokenIcon } from 'utils/functions/getTokenIcon';
import BigNumber from 'bignumber.js';
import { base64ToHex } from 'muf-utils';

const TransactionsLog = () => {
  const { address } = useGetAccountInfo();
  const [transactions, setTransactions] = useState<any[]>([])
  const { success } = useGetActiveTransactionsStatus();

  useEffect(() => {
    const fetchData = async () => {
        const data = await getUserTransactions(address);
        console.log(data)
        setTransactions(data);
    }
    fetchData()
  }, [success])

  const getResult = (logs:any): any[] => {
    if (!logs || logs.length === 0) return [];

    const events = logs.events.filter((event: any) => event.identifier === 'buy') ?? [];
    
    const prizes = [];
  
    for (const event of events) {
      const identifier = decodeBase64(event.topics[1]);
      const nonce = event.topics[2] === "" ? 0 : hexToNumber(Buffer.from(event.topics[2], 'base64').toString('hex'));
      const amount = new BigNumber(base64ToHex(event.topics[3]), 16).toString();
      
      prizes.push({
        name: nonce === 0 ? identifier : sftDetails.find((sft) => sft.nonce === nonce)?.name,
        nonce: nonce,
        amount: nonce === 0 ? new BigNumber(base64ToHex(event.topics[3]), 16).div(Math.pow(10, 18)).toString() : amount,
      });
    }
    return prizes;
  }
  

  return (
    <Row className='mt-3'>
        <Col xs={12}>
          <h1 className='text-center'>Transactions Log</h1>
        </Col>
        <Col xs={12}>
          <Table className='mission-log' responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Hash</th>
                <th>Prize</th>
              </tr>
            </thead>
            <tbody>
              {
                transactions.map((transaction) => (
                    <tr key={transaction.txHash}>
                        <td>
                            {new Date(transaction.timestamp * 1000).toLocaleDateString()}{' '}
                            {new Date(transaction.timestamp * 1000).getHours()
                            + ':' +
                            new Date(transaction.timestamp * 1000).getMinutes()}
                        </td> 
                        <td>
                            <a
                                href={`${EXPLORER_URL}/transactions/${transaction.txHash}`}
                                target='_blank'
                                rel='norel'
                                style={{
                                    color: 'unset'
                                }}
                            >
                                {transaction.txHash} <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </a>
                        </td> 
                        <td>
                          {
                            getResult(transaction.logs).map((prize: any) => (
                              <div>x{prize.amount} {prize.name}</div>
                            ))
                          }
                        </td> 
                    </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
    </Row>
  )
}

export default TransactionsLog