import React, { FC } from 'react'
import { DisplaySftsSelectable } from 'components/DisplaySftsSelectable'
import account from 'store/AccountStore'
import { Category } from '../store/SftStore'
import { getAllowedNonces } from '../hooks/getAllowedNonces'
import { Col, Row } from 'react-bootstrap'

const DisplaySftsStaked: FC<Props> = ({ target }) => {
    const { sftsStaked } = account
    const [sftsFiltred, setSftsFiltred] = React.useState<any[]>([])

    React.useEffect(() => {
        if(sftsStaked.length === 0) return
        const allowedNonces = getAllowedNonces(target);
        const sftsFiltred = sftsStaked.filter((sft) => allowedNonces.includes(Number(sft.nonce)))
        setSftsFiltred(sftsFiltred)
    }, [sftsStaked])

  return (
    <>
        <Row>
            <Col>
                <h3 className='text-center'>Staked SFTs</h3>
            </Col>
        </Row>
        {
            sftsFiltred.length === 0 ? (
                <Row>
                    <Col>
                        <p className='text-center'>No SFTs available</p>
                    </Col>
                </Row>
            ) : (
                <DisplaySftsSelectable
                    sfts={sftsFiltred}
                    category={Category.unStake}
                    showInfo
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                />
            )
        }
    </>
  )
}

interface Props {
    target: string
}

export default DisplaySftsStaked