import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DisplaySft } from './DisplaySft';
import { observer } from 'mobx-react-lite';
import { Category } from '../../pages/Map/store/SftStore';

export const DisplaySftsSelectable: FC<Props> = observer(({ sfts, category, showInfo, xs, sm, md, lg }) => {
    return (
      <Row>
        {sfts.map((sft, idx) => (
          <Col key={idx} xs={xs || 12} sm={sm || 4} md={md || 3} lg={lg || 3} className='mb-2'>
            <DisplaySft sft={sft} category={category} showInfo={showInfo} />
          </Col>
        ))}
      </Row>
    );
  }
);

interface Props {
  sfts: any[];
  category?: Category;
  showInfo?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}
