import { NftType } from "@multiversx/sdk-dapp/types/tokens.types";
import axios from "axios";
import { API_URL, COLLECTION_IDENTIFIER, raidCharacters } from "config";

export const getUserCharacters = async (address: string): Promise<NftType[]> => {
    try {
      const { data } = await axios.get(
        API_URL +
          '/accounts/' +
          address +
          '/nfts?collection=' +
          COLLECTION_IDENTIFIER
      );
      if(data.length === 0) return [];
      return data.filter((sft: NftType) => raidCharacters.includes(sft.nonce));
    } catch (err) {
      console.log(err);
      return [];
    }
  }