import { PageLayout } from 'components/PageLayout';
import { observer } from 'mobx-react-lite';
import { ComingSoon } from 'pages/ComingSoon';
import { Col, Row } from 'react-bootstrap';
import Production from './components/Production';
import { useNavigate, useParams } from 'react-router-dom';
import Army from './components/Army';
import Achievements from './components/Achievements';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'


export const Profile = observer(() => {
  const { address } = useParams();
  const { address: current_address } = useGetAccountInfo();
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Row className='mb-3'>
        <Col>
          <h1 className='text-center'>Profile </h1>
        </Col>
      </Row>
      {
        address === current_address && (<Row className='mb-3'>
        <Col className='text-center'>
            <button className="btn custom-btn" onClick={()=> navigate('/dashboard/wallet')}>MyWallet</button>
        </Col>
      </Row>)
      }
      <Row className='mb-3'>
        <Col xs={12} md={6}>
          <Production address={address} />
        </Col>
        <Col xs={12} md={6}>
          <Row className='mb-3'>
            <Col>
                <div className='mission-card pb-4 px-2'>
                    <h1 className='text-center fw-600 profile-badge mb-3'>Guild</h1>
                    <Row>
                        <Col xs={12} md={3}>
                            <img src="https://estar.games/images/logo.svg" width={100} height={"100%"} alt="logo estar.games" style={{
                                background: '#000000',
                                borderRadius: '18px',
                                padding: '0px 7px',
                                display: 'block',
                                margin: '0 auto'
                            }} />
                        </Col>
                        <Col xs={12} md={9}>
                            <h4>Estar.Games</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing.</p>
                            <button className='btn custom-btn'>View</button>
                        </Col>
                    </Row>
                </div>
            </Col>
          </Row>
          <Army address={address} />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Achievements address={address} /> 
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <div className='mission-card'>
            <h2 className='text-center fw-600 profile-badge mb-3'>Special NFT</h2>
            <ComingSoon />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
});
