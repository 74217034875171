import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

export const DeadlineTimer = observer((
  { 
    deadline,
    onlyTime = true,
    overText,
    overFunc
  }: {
    deadline: number;
    onlyTime?: boolean;
    overText: string;
    overFunc?: () => void
  }
  ) => {
  const [timer, setTimer] = useState(deadline - Math.floor(Date.now() / 1000));

  useEffect(() => {
    if(timer < 1) return
    const timerInterval = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  useEffect(() => {
    if(timer < 1) {
      if(overFunc) overFunc();
    }
  }, [timer])

  const days = Math.floor(timer / 86400);
  const hours = Math.floor((timer % 86400) / 3600);
  const minutes = Math.floor((timer % 3600) / 60);
  const seconds = Math.floor(timer % 60);

  if(onlyTime) return (
    <>
      {timer <= 0 ? <p className='text-center'>{overText}</p> : (
        <>
            {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
        </>
      )}
    </>
  );

  return (
    <>
      {timer <= 0 ? <p className='text-center'>{overText}</p> : (
        <>
            {days} days {hours.toString().padStart(2, '0')} hours {minutes.toString().padStart(2, '0')} minutes {seconds.toString().padStart(2, '0')} seconds
        </>
      )}
    </>
  );
});
