import { useEffect, useState } from 'react';
import { CraftItem } from 'utils/types';
import { MICROSERVICE_URL } from 'config';
import axios from 'axios';
import { ItemsList } from './components/ItemsList';
import { PageLayout } from 'components/PageLayout';
import { Col, Row } from 'react-bootstrap';
import BazaarSelector from 'components/BazaarSelector';

export const Craft = () => {
  const [items, setItems] = useState<CraftItem[]>([]);

  useEffect(() => {
    const fetchCraftItems = async () => {
      try {
        const { data } = await axios.get(MICROSERVICE_URL + '/craft/items');
        setItems(data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchCraftItems()
  }, []);

  return <PageLayout>
    <Row className='mb-3'>
      <Col xs={12}>
        <BazaarSelector />
      </Col>
      <Col xs={12}>
        <h1 className='text-center hero-title'>Crafting Workshop</h1>
      </Col>
    </Row>
    <Row className='mb-3'>
      <Col>
        
        <p className='faction-description'>In the mystical lands of <b>xCastle</b>, brave adventurers emerge from every corner, seeking glory and power. Their quests and relentless staking yield bountiful resources, the very lifeblood of their dominion. With each treasured resource, the foundation of their mighty castles strengthens, and their kingdoms expand. </p>
      </Col>
    </Row>
    <Row>
      <ItemsList items={items} />
    </Row>
  </PageLayout>;
};
