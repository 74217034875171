import { Address, TokenTransfer } from "@multiversx/sdk-core/out";
import { getChainID, refreshAccount } from "@multiversx/sdk-dapp/utils"
import { raidAddress } from "config";
import { raidAbi } from "utils/abi/raid";
import { getSmartContract } from "utils/functions/getSmartContract";
import BigNumber from 'bignumber.js';
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { NftTypeWithQuantity } from "utils/types";

export const sendFightTransaction = async (address: string, sfts: NftTypeWithQuantity[]) => {
    const contract = getSmartContract(raidAbi, new Address(raidAddress))
    if(sfts.length === 0) return;
    const transaction = contract.methods.fight().withMultiESDTNFTTransfer(sfts.map((sft) => new TokenTransfer({
        tokenIdentifier: sft.collection,
        nonce: sft.nonce,
        amountAsBigInteger: new BigNumber(sft.quantity)
    })))
    .withSender(new Address(address))
    .withChainID(getChainID())
    .withGasLimit(60_000_000)
    .buildTransaction()
    refreshAccount();
    sendTransactions({
        transactions: transaction
    });
}
