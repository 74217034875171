import axios from "axios"
import { MICROSERVICE_URL } from "config"
import { Map } from "utils/types";

export const getIsland = async (id: string): Promise<Map | undefined> => {
    try {
        const { data } = await axios.get(`${MICROSERVICE_URL}/islands/${id}`);
        return data;
    } catch (error) {
        return;
    }
}