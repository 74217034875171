import { getBossAttacks } from "./getBossAttacks"
import { PlayerAttack } from "utils/types";
import { getBossInitialHp } from "./getBossInitialHp";
import { getUserReward } from "./getUserReward";

export const getPlayersAttack = async (boss_id: number, reward: number): Promise<PlayerAttack[]> => {
    const attacks = await getBossAttacks(boss_id);
    const boss_hp = await getBossInitialHp(boss_id);
    const addresses: string[] = [];
    const players: PlayerAttack[] = [];

    for(const attack of attacks) {
        if(!addresses.includes(attack.address)) addresses.push(attack.address)
    }

    for(const address of addresses) {
        let total_attack = 0;
        const player_attacks = attacks.filter((attack) => attack.address === address);

        for(const player_attack of player_attacks) {
            total_attack += Number(player_attack.attack);
        }
        players.push({
            address: address,
            attack: total_attack.toString(),
            reward: await getUserReward(reward, boss_hp, total_attack)
        })
    }
    return players.sort((a, b) => Number(b.attack) - Number(a.attack));;
}