import React from 'react';
import { Spinner } from 'react-bootstrap';

export const Loading = ({ loadingText }: { loadingText?: string }) => {
  return (
    <>
      <Spinner animation='border' className='d-block mx-auto' />
        {loadingText && (
          <h6 className='text-muted text-center'>{loadingText}</h6>
        )}
    </>
  );
};
