export const imagePaths = {
    grass: require('../../../assets/island/grass.png'),
    water: require('../../../assets/island/water.png'),
    mountain: require('../../../assets/island/mountain.png'),
    hill: require('../../../assets/island/hill.png'),
    rock: require('../../../assets/island/rock.png'),
    forest: require('../../../assets/island/forest.png'),
    outpost: require('../../../assets/island/outpost.png'),
    castle: require('../../../assets/island/castle.png'),
    corner: require('../../../assets/island/corner.png'),
    relic1: require('../../../assets/island/relic1.png'),
    relic2: require('../../../assets/island/relic2.png'),
    relic3: require('../../../assets/island/relic3.png'),
    relic4: require('../../../assets/island/relic4.png'),
    relic5: require('../../../assets/island/relic5.png'),
    relic6: require('../../../assets/island/relic6.png'),
    relic7: require('../../../assets/island/relic7.png'),
    relic8: require('../../../assets/island/relic8.png'),
    attack: require('../../../assets/island/attack.png')
};