import axios from "axios";
import { MICROSERVICE_URL } from "config";
import { Boss } from "utils/types";

export const getCurrentBoss = async (): Promise<Boss | undefined> => {
    try {
        const { data } = await axios.get(MICROSERVICE_URL + '/raid/boss')
        return data
    } catch (error) {
        console.log(error)
    }
}