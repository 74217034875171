import React, {useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { CraftItem } from 'utils/types'
import { craftItemTransaction } from 'utils/transactions/craftItem';
import { observer } from 'mobx-react-lite';
import nonceToImageMap from 'utils/types/imageMappings';
import { Beer, Bread, Iron, Shield, Stone, Wood } from 'assets/icons';
import '../Craft.css';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { DisplaySftsInfo } from '../../../components/DisplaySftsInfo';
import BigNumber from 'bignumber.js';

export const Item = observer((
  {item, onChange, isSelected, onIncrease, onDecrease, selectedAmount = 0}: {item: CraftItem, onChange: (item: CraftItem) => void, isSelected: boolean, onIncrease: () => void, onDecrease: () => void, selectedAmount?: number}
  ) => {

    const { address } = useGetAccountInfo();
    const [loading, setLoading] = useState<boolean>(false);

      const resources = [
        {
          name: 'FOOD-46261d',
          icon: <Bread width={16} fill='white' />
        },
        {
          name: 'WOOD-2f70ee',
          icon: <Wood width={16} fill='white' />
        },
        {
          name: 'BEER-093bf2',
          icon: <Beer width={16} fill='white' />
        },
        {
          name: 'IRON-abe3cd',
          icon: <Iron width={20} fill='white' />
        },
        {
          name: 'STONE-6830a4',
          icon: <Stone width={18} fill='white' />
        },
        {
          name: 'WARGEAR-932f1d',
          icon: <Shield width={14} fill='white' />
        },
        {
          name: 'ESTAR-461bab',
          icon: <img src='https://media.elrond.com/tokens/asset/ESTAR-461bab/logo.svg' width={20} height={20} />
        }
      ];
    
      const formattedAmount = (amount: string) => {
        return new BigNumber(amount).div(Math.pow(10, 18)).toString();
      };
      

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const verify = () => {
        craftItemTransaction({sender: address, prices: item.prices, amount: selectedAmount, id: item.id});
    }
    const imageSrc = nonceToImageMap[String(item.nonce)] || '//placekitten.com/300/250';

    if(loading) return (<Col xs={12} sm={6} md={4} lg={3} xl={3}>
        <Card className="loading-skeleton">
            <Card.Img src="//placekitten.com/300/250" alt="..." style={{ minHeight: "250px" }} />
            <Card.Header className='d-flex justify-content-between align-items-center'>
                <h5>xCastle Craft</h5>
                
                <button className="btn btn-primary">craft</button>
            </Card.Header>
        </Card>
    </Col>)

  return (
    <Col xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '10px' }}>
        <Card className={`card-craft ${isSelected && 'card-craft-selected'}`} onClick={() => onChange(item)} >
            <div className='image-container'>
                <Card.Img src={imageSrc} style={{ borderRadius: '10px 10px 0 0', minHeight: "200px" }} />
                <div className='image-badge-right-craft2'>
                    <h5 className='pt-1 mr-1'>x{item.amount}</h5>
                    <div onClick={openModal} className="info-icon">?</div>
                    <DisplaySftsInfo nonce={Number(item.nonce)} showModal={isModalOpen} closeModal={closeModal} />
                </div>
                
            </div>
            <hr />
           
            <Card.Header className='d-flex justify-content-between align-items-center card-header-craft'>
                <Row className='align-items-end'>
                {item.prices.map((price, index) => (
                    <Col xs={6} md={4} className='mt-1' key={index}>
                      {price.token_identifier === 'XCASTLE-ee733b' ? (
                        <div key={index} className='resource-item'>
                          <img
                            src={nonceToImageMap[String(price.token_nonce)] || 'default-image-path'}
                            alt="XCastle"
                            width={"30px"}
                          />
                          <span> &nbsp;  {price.amount}</span>
                        </div>
                      ) : (
                        <div key={index} className='resource-item'>
                          {
                          resources.find(
                            (resource: { name: string; icon: JSX.Element }) => resource.name === price.token_identifier
                          )?.icon
                          }
                        <span>
                          {formattedAmount(price.amount)}
                        </span>
                        </div>
                      )}
                    </Col>
                          ))}
                </Row>
                
            </Card.Header>
            {
              isSelected && (
                <>
                  <div className='d-flex justify-content-between'>
              <div>
                <button
                  className='btn custom-btn border-fit-1'
                  onClick={() => {
                    if (selectedAmount > 1) {
                      onDecrease()
                    }
                  }}
                >
                  -
                </button>
              </div>
              
              <h4>{selectedAmount}</h4>
              <div>
                <button
                  className='btn custom-btn border-fit-2'
                  onClick={() => {
                    if (selectedAmount < item.amount) {
                      onIncrease()
                    }
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <Button className='d-block mx-auto custom-btn' onClick={verify}>Craft</Button>
                </>
              )
            }
        </Card>
    </Col>
  )
});
