import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ActiveMission } from 'utils/types'
import { Col, Row } from 'react-bootstrap'
import { endMissionTransaction } from 'utils/transactions'
import { MissionProgress, DeadlineTimer } from 'components'

export const UserActiveMission: FC<Props> = observer(({ activeMission }) => {
  const [deadlineReached, setDeadlineReached] = useState<boolean>(false);

  useEffect(() => {
    setDeadlineReached(activeMission!.end_time <= Math.floor(Date.now() / 1000))

  }, [activeMission])

  if(!activeMission) return null;

  return (
    <Row className='mb-3'>
      <Col xs={12} className='mission-card'>
        <Row className='p-3' style={{
            alignItems: 'center'
          }}
        >
          <Col xs={12} md={8}>
            <Row>
              {
                activeMission.fighters.map((fighter) => (
                  <Col xs={12} sm={6} md={4} lg={3} key={fighter.token_nonce} className='mb-2'>
                    <div style={{
                        position: 'relative',
                        width: '100%'
                      }}
                    >
                      <img
                        src={`/assets/img/collection/${fighter.token_nonce}.png`}
                        alt="sft-image"
                        className='mission-card-box-image'
                      />
                      <div className="mission-card-box-image-info">
                        <div className='text-center'>
                          <h4>x{fighter.amount}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              }
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <div className="mission-card-box-left ml-md-4 mt-3 mt-md-0">
              <h2 className='text-center mission-card-box-left-id'>Tier {activeMission.tier}</h2>
              <h3 className='text-center'>
                <DeadlineTimer deadline={activeMission.end_time} overText="It's time to claim your rewards!" />
              </h3>
              <MissionProgress
                id={activeMission.id}
                deadline={activeMission.end_time}
                setDeadlineReached={setDeadlineReached}
              />
              <button
                className={`mission-card-box-left-btn mt-5 ${deadlineReached === false && 'btn-disabled'}`}
                onClick={() => endMissionTransaction(activeMission.id)}
                disabled={deadlineReached === false}
              >
                Claim
              </button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
})

interface Props {
  activeMission: ActiveMission
}
