import React from 'react';
import { getRewardPrize } from '../hooks';
import { getTopRewards } from '../hooks/getTopRewards';
import medalGold from 'assets/icons/medal-gold.svg';
import medalSilver from 'assets/icons/medal-silver.svg';
import medalBronze from 'assets/icons/medal-bronze.svg'

const LeaderboardItem: React.FC<{
  wallet: string;
  rank: number;
  score: number;
  userWallet: string;
}> = ({ wallet, rank, score, userWallet }) => {
  const rewardIcon = getTopRewards(wallet);

  const formatWalletAddress = (wallet: string) => {
    return `${wallet.substring(0, 5)}...${wallet.substring(wallet.length - 5)}`;
  };

  const isUserWallet = wallet === userWallet;

  let iconSrc;
  switch (rewardIcon) {
    case 'medal-gold':
      iconSrc = medalGold;
      break;
    case 'medal-silver':
      iconSrc = medalSilver;
      break;
    case 'medal-bronze':
      iconSrc = medalBronze;
      break;
    // ... alte cazuri
    default:
      iconSrc = null; // sau poți seta un icon implicit
  }

  return (
    <tr>
      <td style={{ backgroundColor: wallet === userWallet ? '#FBF2EB' : '' }}>{iconSrc && <img src={iconSrc} alt="Reward Icon" style={{ width: '20px', height: '20px' }} />}</td>
      <td style={{ backgroundColor: wallet === userWallet ? '#FBF2EB' : '' }}>{rank}</td>
      <td style={{ backgroundColor: wallet === userWallet ? '#FBF2EB' : '' }}>
       {formatWalletAddress(wallet)}
      </td><td style={{ backgroundColor: wallet === userWallet ? '#FBF2EB' : '' }}>{score}</td>
      <td style={{ backgroundColor: wallet === userWallet ? '#FBF2EB' : '' }}>
        {getRewardPrize(rank)}{' '}
        <img
          src='https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/ESTAR-461bab/logo.svg'
          alt='Prize Logo'
          style={{ width: '20px', height: '20px' }}
        />
      </td>
    </tr>
  );
};

export { LeaderboardItem };