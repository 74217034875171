import React, { FC, useState } from 'react'
import { PageLayout } from 'components/PageLayout';
import { Col, Row, Carousel, Button } from 'react-bootstrap';
import '../Lore.css';

export const Story: FC = () => {
    const [index, setIndex] = useState(0);
    const totalImages = 7;

    const handleSelect = (selectedIndex: number, e: any) => {
      setIndex(selectedIndex);
    };

    const nextImage = () => {
        setIndex((oldIndex) => oldIndex + 1 === totalImages ? oldIndex : oldIndex + 1);
      };
  
      const prevImage = () => {
        setIndex((oldIndex) => oldIndex - 1 >= 0 ? oldIndex - 1 : oldIndex);
      };

    return (
      <PageLayout>
       
        <Row className='mb-3'>
            <Col>
            <Carousel activeIndex={index} onSelect={handleSelect} interval={null} pause={"hover"} wrap={false}>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/Cover.jpeg')}
                  alt="Cover"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/page1.png')}
                  alt="Prologue 1"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/page2.png')}
                  alt="Prologue 2"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/page3.png')}
                  alt="Prologue 3"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/chapter1-page1.png')}
                  alt="Chapter 1 Page 1"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/chapter1-page2.png')}
                  alt="Chapter 1 Page 2"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/chapter1-page3.png')}
                  alt="Chapter 1 Page 3"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/chapter1-page4.png')}
                  alt="Chapter 1 Page 4"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/chapter1-page5.png')}
                  alt="Chapter 1 Page 5"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={require('../../../assets/img/tobecontinued.jpg')}
                  alt="To be continue"
                  className="responsive-image"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Carousel.Item>
            </Carousel>
            <div className="d-flex justify-content-center mt-3">
              {index !== 0 && <Button variant="primary" onClick={prevImage} className="mr-2 custom-btn">Prev Page</Button>}
              {index !== totalImages - 1 && <Button variant="primary" className="mr-2 custom-btn" onClick={nextImage}>Next Page</Button>}
            </div>
          </Col>
          
        </Row>
      </PageLayout>
    );
};