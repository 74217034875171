import axios from "axios"
import { MICROSERVICE_URL } from "config"
import { BossAttack } from "utils/types/BossAttack"

export const getBossAttacks = async (id: number): Promise<BossAttack[]> => {
    try {
        const { data } = await axios.get(MICROSERVICE_URL + '/raid/boss/' + id)
        return data
    } catch (error) {
        console.log(error)
        return []
    }
}