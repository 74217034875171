import React from 'react';
import { Modal } from 'react-bootstrap';
import { sftDetails } from 'config'; // Presupunem că acesta este importul corect pentru datele tale

interface DisplaySftsInfoProps {
  nonce: number; // Identificator unic pentru fiecare NFT
  showModal: boolean; // Starea pentru a controla afișarea modalului
  closeModal: () => void; // Funcție pentru a închide modalul
}

export const DisplaySftsInfo: React.FC<DisplaySftsInfoProps> = ({ nonce, showModal, closeModal }) => {
  // Găsește detaliile NFT folosind nonce-ul primit prin props
  const nftDetails = sftDetails.find(detail => detail.nonce === nonce);

  const displayInfo = (info: string | undefined) => {
    if (info === 'none' || info === '' || info === undefined) {
      return null;
    }
    return info;
  };

  return (
    <Modal show={showModal} onHide={closeModal} className="modal-custom">
      <Modal.Header >
        <Modal.Title>{nftDetails?.name}</Modal.Title>
        <button type="button" className="close" aria-label="Close" onClick={closeModal}>
    <span aria-hidden="true">&times;</span> {/* Acesta este simbolul X */}
  </button>
      </Modal.Header>
      <Modal.Body>
        {/* Afișează informațiile doar dacă nu sunt 'none' sau empty */}
        {displayInfo(nftDetails?.description) && <p>Description: {nftDetails?.description}</p>}
        {displayInfo(nftDetails?.type) && <p>Type: {nftDetails?.type}</p>}
        {displayInfo(nftDetails?.productionBonus) && <p>Production Bonus: {nftDetails?.productionBonus}</p>}
        {displayInfo(nftDetails?.attack) && <p>Attack: {nftDetails?.attack}</p>}
        {displayInfo(nftDetails?.defense) && <p>Defense: {nftDetails?.defense}</p>}
        {displayInfo(nftDetails?.multiplierAttack) && <p>Multiplier Attack: {nftDetails?.multiplierAttack}</p>}
        {displayInfo(nftDetails?.multiplierDefense) && <p>Multiplier Defense: {nftDetails?.multiplierDefense}</p>}
        {displayInfo(nftDetails?.element) && <p>Element: {nftDetails?.element}</p>}
        {displayInfo(nftDetails?.carrying) && <p>Carrying: {nftDetails?.carrying}</p>}
        {displayInfo(nftDetails?.foodProduction) && <p>Daily Food production: {nftDetails?.foodProduction}</p>}
        {displayInfo(nftDetails?.ironProduction) && <p>Daily Iron production: {nftDetails?.ironProduction}</p>}
        {displayInfo(nftDetails?.wargearProduction) && <p>Daily WarGear production: {nftDetails?.wargearProduction}</p>}
        {displayInfo(nftDetails?.beerProduction) && <p>Daily Beer production: {nftDetails?.beerProduction}</p>}
        {displayInfo(nftDetails?.woodProduction) && <p>Daily Wood production: {nftDetails?.woodProduction}</p>}
        {displayInfo(nftDetails?.stoneProduction) && <p>Daily Stone production: {nftDetails?.stoneProduction}</p>}
        {displayInfo(nftDetails?.eccuProduction) && <p>Daily ECCU production: {nftDetails?.eccuProduction}</p>}
        {displayInfo(nftDetails?.score) && <p>Leaderboard Score points: {nftDetails?.score}</p>}

      </Modal.Body>
      <Modal.Footer>
        <button onClick={closeModal} className="info-button">Close</button>
      </Modal.Footer>
    </Modal>
  );
};
