import { CraftItem } from 'utils/types'
import { Item } from './Item'
import { useState } from 'react'

export const ItemsList = ({items}:{items: CraftItem[]}) => {
  const [selectedItem, setSelectedItem] = useState<CraftItem>();

  const handleChange = (item: CraftItem) => {
    if(selectedItem && selectedItem.id === item.id) return;

    setSelectedItem({
      ...item,
      amount: 1
    })
  }

  const handleIncrease = () => {
    if(!selectedItem) return;
    let new_item = {
      ...selectedItem,
      amount: selectedItem.amount + 1
    }
    setSelectedItem(new_item)
  }

  const handleDecrease = () => {
    if(!selectedItem) return;
    let new_item = {
      ...selectedItem,
      amount: selectedItem.amount - 1
    }
    setSelectedItem(new_item)
  }

  return (
    <>
      {items.map((item, index) => (
        <Item
          key={index}
          item={item}
          selectedAmount={selectedItem?.amount}
          onChange={handleChange}
          isSelected={selectedItem?.id === item.id}
          onIncrease={handleIncrease}
          onDecrease={handleDecrease}
        />
      ))}
    </>
  )
}
