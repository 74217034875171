export function getAllowedNonces(caseType: string): number[] {
    switch (caseType) {
      case 'mill':
        return [2, 19, 23, 29];
      case 'tower':
        return [4, 18, 25, 31];
      case 'tavern':
        return [6, 17, 24, 30];
      case 'bank':
        return [5, 15, 21, 26];
      case 'forge':
        return [3, 16, 22, 27];
      case 'house':
        return [7, 14, 20, 28];
      case 'quarry':
        return [38, 44, 52, 58];
      case 'ironmine':
        return [36, 42, 50, 36, 56];
      case 'lumberyard':
        return [37, 43, 51, 57];
      case 'blacksmith':
        return [33, 39, 46, 53];
      case 'brewery':
        return [34, 40, 47, 54];
      case 'farm':
        return [35, 41, 49, 55];
      default:
        return [];
    }
  }