import React from 'react'
import { PageLayout } from 'components/PageLayout'
import { Col, Row } from 'react-bootstrap'
import Bid from './components/Bid'
import BazaarSelector from 'components/BazaarSelector'
import { ComingSoon } from 'pages/ComingSoon'

export const Auction = () => {
  return (
    <PageLayout>
        <Row className='mb-3'>
            <Col xs={12} className='mb-3'>
                <BazaarSelector />
            </Col>
            <Col xs={12}>
                <h1 className='text-center'>Auction</h1>
            </Col>
        </Row>
        {/* <Row>
            <Col>
                <Bid />
            </Col>
        </Row> */}
        <ComingSoon />
    </PageLayout>
  )
}