import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import account from 'store/AccountStore'
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks'
import { Col, Row, Table } from 'react-bootstrap'
import { getTokenIcon } from 'utils/functions/getTokenIcon'
import { base64ToHex, hexToNumber, hexToString } from 'muf-utils'
import BigNumber from 'bignumber.js'
import { EXPLORER_URL } from 'config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { getShortAddress } from 'utils/functions/getShortAddress'

const MissionLog: FC = observer(() => {
    const { address } = useGetAccountInfo();
    const { missionsHistory } = account;
    const { success } = useGetActiveTransactionsStatus()

    useEffect(() => {
        account.loadMissionsHistory(address);
    }, [address, success])

    return (
        <>
            <Row className='mt-3'>
                <Col xs={12}>
                    <h1 className='text-center'>Mission Log</h1>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    <Table className='mission-log' responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Time</th>
                                <th>SFT Name</th>
                                <th>Reward</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {missionsHistory.map((transaction, index) => (
                                <tr key={index}>
                                    <td>
                                        {
                                            hexToNumber(hexToString(base64ToHex(transaction.data)).split('@')[1])
                                        }
                                    </td>
                                    <td>
                                        {
                                            new Date(transaction.timestamp * 1000).toLocaleString()
                                        }
                                    </td>
                                    <td>
                                        {
                                            transaction.operations.filter((operation: any) => operation.type === 'nft').map((operation: any) => (
                                                <div key={operation.id}>
                                                    x{operation.value} {operation.name}
                                                </div>
                                            ))
                                        }
                                    </td>
                                    <td>
                                        {
                                            transaction.operations.filter((operation: any) => operation.type === 'esdt').map((operation: any) => (
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div className='mr-2'>
                                                        x{new BigNumber(operation.value).div(Math.pow(10, 18)).toString()}
                                                    </div>
                                                    {getTokenIcon(operation.identifier.split('-')[0].toUpperCase())}
                                                </div> 
                                            ))
                                        }
                                    </td>
                                    <td>
                                        <a
                                            href={`${EXPLORER_URL}/transactions/${transaction.txHash}`}
                                            target='_blank'
                                            rel='norel'
                                            style={{
                                                color: 'unset'
                                            }}
                                        >
                                            {getShortAddress(transaction.txHash)} <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
})

export default MissionLog